import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BackLink,
  ClientLink,
  DateTime,
  ExportDropdown,
  Hours,
  Icon,
  InternalClientTooltip,
  PeriodFilter,
  ProjectLink,
  Tooltip,
  YesNoFilter,
} from '~/components';
import AllocationBillableTypeFilter from '~/components/filters/AllocationBillableTypeFilter';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import FilterButton from '~/components/filters/FilterButton';
import FiltersGroup from '~/components/filters/FiltersGroup';
import MemberFiltersBar from '~/components/filters/MemberFiltersBar';
import MemberFiltersGroup from '~/components/filters/MemberFiltersGroup';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import ResourceFiltersBar from '~/components/filters/ResourceFiltersBar';
import ResourceFiltersGroup from '~/components/filters/ResourceFiltersGroup';
import SingleSelectFilter from '~/components/filters/SingleSelectFilter';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import allocationBillableTypes from '~/lookups/allocation-billable-types';
import projectStatuses from '~/lookups/project-statuses.js';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { dateFormats, mimeTypes } from '~/utils';
import ExportDialogAsync from '../components/ExportDialogAsync';
import Report from '../components/Report';
import Table from '../components/table';
import SavedReportActions from '../SavedReportActions';
import { intervals, intervalsByScope } from './intervals';

const ProjectInfo = styled.div`
  margin-left: 1rem;
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const Member = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${weights.bold};
`;

export default function HoursPlanByResourceAndProject({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();

  const searchParamsConfig = useSearchParamsConfig();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectRecordStatusId: {
      ...searchParamsConfig.recordStatusId,
      default: 'active',
    },
    projectStatuses: {
      ...searchParamsConfig.projectStatuses,
      default: [projectStatuses.not_started, projectStatuses.in_progress],
    },
  }));
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    unit: 'month',

    // Allocation Filters
    allocationBillableTypes: [allocationBillableTypes.billable],

    // Resource Filters
    resourcePractices: [],
    resourceDisciplines: [],
    resourceLocations: [],
    resourceSkills: [],
    resourceStatusId: 'active',
    resourceTypeId: null,
    onlyAllocatedResources: false,

    // Member Filters
    ..._.omit(memberFilters.filters, [
      'memberStatus',
      'memberPractices',
      'memberDisciplines',
      'memberLocations',
      'memberSkills',
    ]),

    // Client Filters
    ...clientFilters.filters,

    // Project filters
    ...projectFilters.filters,
  });

  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          ...searchParamsConfig.periodByUnit,
          default: intervals.next_6_months,
          intervals: (unit) => intervalsByScope[unit],
          defaults: {
            day: intervals.next_30_days,
            week: intervals.next_12_weeks,
            month: intervals.next_6_months,
          },
        },
        unit: { default: 'month', valid: ['day', 'week', 'month'] },

        // Allocation Filters
        allocationBillableTypes: {
          ...searchParamsConfig.allocationBillableTypes,
          default: [allocationBillableTypes.billable],
        },

        // Resource Filters
        resourcePractices: searchParamsConfig.practices,
        resourceDisciplines: searchParamsConfig.disciplines,
        resourceLocations: searchParamsConfig.locations,
        resourceSkills: searchParamsConfig.skills,
        resourceStatusId: { valid: ['active', 'inactive'] },
        resourceTypeId: { valid: ['member', 'placeholder'] },
        onlyAllocatedResources: {
          default: false,
          serialize: (value) => (value === true ? 'true' : 'false'),
          deserialize: (value) => value === 'true',
        },

        // Member Filters
        ..._.omit(memberFilters.searchParamsConfig, [
          'memberStatus',
          'memberPractices',
          'memberDisciplines',
          'memberLocations',
          'memberSkills',
        ]),

        // Client Filters
        ...clientFilters.searchParamsConfig,

        // Project Filters
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      unit: params.unit,

      // Allocation Filters
      allocationBillableTypeId: params.allocationBillableTypes?.map((v) => v.id),

      // Resource Filters
      resourcePracticeId: params.resourcePractices?.map((v) => v.id),
      resourceDisciplineId: params.resourceDisciplines?.map((v) => v.id),
      resourceLocationId: params.resourceLocations?.map((v) => v.id),
      resourceSkillId: params.resourceSkills?.map((v) => v.id),
      resourceStatusId: params.resourceStatusId ?? undefined,
      resourceTypeId: params.resourceTypeId ?? undefined,
      onlyAllocatedResources: params.onlyAllocatedResources === true ? 'true' : undefined,

      // Member Filters
      ...memberFilters.mapUrlSearchParams(params),

      // Client Filters
      ...clientFilters.mapUrlSearchParams(params),

      // Project Filters
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, memberFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().hoursPlanByResourceAndProject(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(values);
    hideFilters();
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .hoursPlanByResourceAndProject(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title>{report.name}</Report.Title>
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />

          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <PeriodFilter
          clearable={false}
          scope={params.unit}
          intervals={intervalsByScope[params.unit]}
          placeholder="Date Range"
          value={params.period}
          onChange={({ target: { value } }) => handleApplyFilters({ period: value })}
        />

        <UnitFilter
          value={params.unit}
          onChange={({ target: { value } }) =>
            handleApplyFilters({
              unit: value,
              period: {
                day: intervals.next_30_days,
                week: intervals.next_12_weeks,
                month: intervals.next_6_months,
              }[value],
            })
          }
        />

        <YesNoFilter
          icon="filter"
          placeholder="Only resources with allocations"
          value={params.onlyAllocatedResources ? 'yes' : 'no'}
          onChange={({ target: { value } }) => handleApplyFilters({ onlyAllocatedResources: value === 'yes' })}
        />

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />

        <MemberFiltersBar filters={params} onChange={handleApplyFilters} />

        <ResourceFiltersBar filters={params} onChange={handleApplyFilters} />

        {!_.isEmpty(params.allocationBillableTypes) && (
          <AllocationBillableTypeFilter
            value={params.allocationBillableTypes}
            onChange={({ target: { value } }) => handleApplyFilters({ allocationBillableTypes: value })}
          />
        )}
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params }) {
  const report = query.data;

  const { resources, projectsById, allocationGroups, periods } = useMemo(() => {
    const resources = _.orderBy(report.resources, (r) => r.name?.toLowerCase());

    const projectsById = _.keyBy(report.projects, 'id');

    const allocationGroups = _.orderBy(report.allocations, (a) => projectsById[a.projectId].name.toLowerCase()).reduce(
      (a, v) => {
        a.total += v.totalHours;

        // Resources
        a.resources[v.resourceId] = a.resources[v.resourceId] || { total: 0, periods: {}, projects: {} };
        a.resources[v.resourceId].total += v.totalHours;
        if (!a.resources[v.resourceId].periods[v.start]) a.resources[v.resourceId].periods[v.start] = 0;
        a.resources[v.resourceId].periods[v.start] += v.totalHours;
        a.resources[v.resourceId].projects[v.projectId] = a.resources[v.resourceId].projects[v.projectId] || {
          total: 0,
          periods: {},
        };

        // Projects
        a.resources[v.resourceId].projects[v.projectId].total += v.totalHours;
        a.resources[v.resourceId].projects[v.projectId].periods[v.start] = v.totalHours;

        // Periods
        if (!a.periods[v.start]) a.periods[v.start] = 0;
        a.periods[v.start] += v.totalHours;

        return a;
      },
      { resources: {}, periods: {}, total: 0 },
    );

    let start = params.period.start;
    let end = params.period.end;

    switch (params.unit) {
      case 'day':
        start = moment(start).format(dateFormats.isoDate);
        end = moment(end).format(dateFormats.isoDate);
        break;

      case 'week':
        start = moment(start).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(end).endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'month':
        start = moment(start).startOf('month').format(dateFormats.isoDate);
        end = moment(end).endOf('month').format(dateFormats.isoDate);
        break;
    }

    const periodCount = moment(end).diff(start, params.unit) + 1;
    const periods = [];
    for (let index = 0; index < periodCount; index++) {
      periods.push(moment(start).add(index, params.unit).format(dateFormats.isoDate));
    }

    return { resources, projectsById, allocationGroups, periods };
  }, [report, params.period.start, params.period.end, params.unit]);

  return (
    <Report.Table>
      <Table>
        <Table.Header>
          <Table.Column sticky minWidth="16rem">
            Member
          </Table.Column>

          {periods.map((p) => (
            <Table.Column key={p} align="right" width="7rem">
              <DateTime value={p} />
            </Table.Column>
          ))}

          <Table.Column align="right" width="7rem">
            Total
          </Table.Column>
        </Table.Header>

        <Table.Body>
          {resources.map((resource) => {
            return (
              <React.Fragment key={resource.id}>
                <Table.GroupRow top="3rem">
                  <Table.Cell>
                    <Member>
                      {resource.name}
                      {{
                        member: () => (
                          <Tooltip message="Member">
                            <Icon icon="user" color={colors.grey25} spaceLeft />
                          </Tooltip>
                        ),
                        placeholder: () => (
                          <Tooltip message="Resource Placeholder">
                            <Icon icon="users" color={colors.grey25} spaceLeft />
                          </Tooltip>
                        ),
                      }[resource.resourceType]()}
                    </Member>
                  </Table.Cell>

                  {periods.map((period) => {
                    const hours = allocationGroups.resources[resource.id]?.periods[period];

                    return (
                      <Table.Cell key={period}>
                        <Hours value={hours} />
                      </Table.Cell>
                    );
                  })}

                  <Table.Cell>
                    <Hours value={allocationGroups.resources[resource.id]?.total} />
                  </Table.Cell>
                </Table.GroupRow>

                {_.map(allocationGroups.resources[resource.id]?.projects, (projectAllocations, key) => {
                  const project = projectsById[key];

                  return (
                    <Table.Row key={key}>
                      <Table.Cell>
                        <ProjectInfo>
                          <p>
                            <ProjectLink project={project} />
                          </p>
                          <small>
                            <ClientLink client={project.client} />
                            {project.client.isInternal && <InternalClientTooltip />}
                          </small>
                        </ProjectInfo>
                      </Table.Cell>

                      {periods.map((period) => {
                        const hours = projectAllocations.periods[period];

                        return (
                          <Table.Cell key={period}>
                            <Hours value={hours} />
                          </Table.Cell>
                        );
                      })}

                      <Table.Cell>
                        <Hours value={projectAllocations.total} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>

            {periods.map((period) => {
              const hours = allocationGroups.periods[period];

              return (
                <Table.Cell key={period}>
                  <Hours value={hours} />
                </Table.Cell>
              );
            })}

            <Table.Cell>
              <Hours value={allocationGroups.total} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>

        <Table.Status total={report.resources.length} label="Resource" isLoading={query.status !== 'ready'} />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <UnitFilter
        value={filters.unit}
        onChange={({ target: { value } }) =>
          handleFilter({
            unit: value,
            period: {
              day: intervals.next_30_days,
              week: intervals.next_12_weeks,
              month: intervals.next_6_months,
            }[value],
          })
        }
      />

      <PeriodFilter
        clearable={false}
        scope={filters.unit}
        intervals={intervalsByScope[filters.unit]}
        placeholder="Date Range"
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      <YesNoFilter
        icon="filter"
        placeholder="Only show resources with allocations"
        value={filters.onlyAllocatedResources ? 'yes' : 'no'}
        onChange={({ target: { value } }) => handleFilter({ onlyAllocatedResources: value === 'yes' })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />

      <ResourceFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup label="Allocation Filters" filters={[filters.allocationBillableTypes]}>
        <AllocationBillableTypeFilter
          value={filters.allocationBillableTypes}
          onChange={({ target: { value } }) => handleFilter({ allocationBillableTypes: value })}
        />
      </FiltersGroup>
    </Report.FiltersDrawer>
  );
}

function UnitFilter(props) {
  return (
    <SingleSelectFilter
      icon="gear"
      placeholder="Unit"
      clearable={false}
      renderValue={(value) => value.name}
      options={[
        { id: 'month', name: 'Month' },
        { id: 'week', name: 'Week' },
        { id: 'day', name: 'Day' },
      ]}
      {...props}
    />
  );
}
