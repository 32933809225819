import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BackLink, ExportDropdown, HelpTooltip, Hours, Inline, Percent, RouteLink, YesNoFilter } from '~/components';
import FilterButton from '~/components/filters/FilterButton';
import MemberFiltersBar from '~/components/filters/MemberFiltersBar';
import MemberFiltersGroup from '~/components/filters/MemberFiltersGroup';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter';
import TimeFiltersBar from '~/components/filters/TimeFiltersBar';
import TimeFiltersGroup from '~/components/filters/TimeFiltersGroup';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useMemberFilters from '~/hooks/useMemberFilters';
import employmentTypes from '~/lookups/employment-types';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { intervalOptions, QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ExportDialogAsync from '../components/ExportDialogAsync';
import Report from '../components/Report';
import Table from '../components/table';
import SavedReportActions from '../SavedReportActions';

const columns = {
  capacity: { default: 'capacity', time_off_adjusted: 'capacityTimeOffAdjusted' },
  billableUtilization: { default: 'billableUtilization', time_off_adjusted: 'billableUtilizationTimeOffAdjusted' },
  clientUtilization: { default: 'clientUtilization', time_off_adjusted: 'clientUtilizationTimeOffAdjusted' },
  productiveUtilization: {
    default: 'productiveUtilization',
    time_off_adjusted: 'productiveUtilizationTimeOffAdjusted',
  },
  totalUtilization: { default: 'totalUtilization', time_off_adjusted: 'totalUtilizationTimeOffAdjusted' },
  targetAttainment: { default: 'targetAttainment', time_off_adjusted: 'targetAttainmentTimeOffAdjusted' },
};

const NoMemberPractice = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.grey25};
`;

function UtilizationByMemberPractice({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const searchParamsConfig = useSearchParamsConfig();

  const memberFilters = useMemberFilters(() => ({
    employmentTypes: { default: [employmentTypes.employee], ...searchParamsConfig.employmentTypes },
    memberBillableType: { default: 'billable', ...searchParamsConfig.memberBillableType },
  }));

  const [params, setParams] = useState({
    period: null,
    approvalStatuses: [],
    clientApprovalStatuses: [],
    utilizationMode: 'default',
    includeMembersWithoutCapacity: false,
    sort: new QuerySort('name', 'asc'),
    ...memberFilters.filters,
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { default: intervalOptions.this_month_to_date, ...searchParamsConfig.period },
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        utilizationMode: {
          valid: ['default', 'time_off_adjusted'],
          default: workspace.defaultAdjustForTimeOff ? 'time_off_adjusted' : 'default',
        },
        includeMembersWithoutCapacity: {
          default: false,
          serialize: (value) => (value === true ? 'true' : 'false'),
          deserialize: (value) => value === 'true',
        },
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, memberFilters, workspace.defaultAdjustForTimeOff],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      statusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      includeMembersWithoutCapacity: params.includeMembersWithoutCapacity ? 'true' : undefined,
      sort: params.sort,
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, features.clientApprovals, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().utilizationByMemberPractice(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleUtilizationModeChange = (utilizationMode) => {
    let sort = params.sort;

    const key = _.findKey(columns, (v) => v[params.utilizationMode] === params.sort.column);
    if (key) {
      const column = columns[key][utilizationMode];
      sort = new QuerySort(column, params.sort.direction);
      setQuery((state) => ({ ...state, status: 'sorting' }));
    }

    setParams({ ...params, sort, utilizationMode });
    searchParams.set({ utilizationMode, sort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .utilizationByMemberPractice(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title>{report.name}</Report.Title>
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />

          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={() => {
                    handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={() => {
                    handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ReportPeriodFilter
          clearable={false}
          value={params.period}
          onChange={({ value }) => handleApplyFilters({ period: value })}
        />

        <YesNoFilter
          icon="filter"
          placeholder="Members without capacity"
          value={params.includeMembersWithoutCapacity ? 'yes' : 'no'}
          onChange={({ target: { value } }) => handleApplyFilters({ includeMembersWithoutCapacity: value === 'yes' })}
        />

        <Inline>
          <YesNoFilter
            data-testid="adjust_time_off"
            icon="gear"
            placeholder="Adjust for Time Off"
            value={params.utilizationMode === 'time_off_adjusted' ? 'yes' : 'no'}
            onChange={({ target: { value } }) =>
              handleUtilizationModeChange(value === 'yes' ? 'time_off_adjusted' : 'default')
            }
          />
          <HelpTooltip
            style={{ marginLeft: '.5rem' }}
            message="Reduces the capacity by the number of Time Off hours taken during the period."
          />
        </Inline>

        <MemberFiltersBar filters={params} onChange={handleApplyFilters} />

        <TimeFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;

  const { workspace } = useWorkspace();

  const memberFilters = useMemberFilters();

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time-entries?${new QueryString(
        {
          start: params.period?.start,
          end: params.period?.end,
          status: params.approvalStatuses?.map((v) => v.id),
          clientStatus: params.clientApprovalStatuses?.map((v) => v.id),
          ...memberFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, memberFilters],
  );

  const totals = {
    default: {
      capacity: report.totals.capacity,
      billableUtilization: report.totals.billableUtilization,
      clientUtilization: report.totals.clientUtilization,
      productiveUtilization: report.totals.productiveUtilization,
      totalUtilization: report.totals.totalUtilization,
      targetAttainment: report.totals.targetAttainment,
    },

    time_off_adjusted: {
      capacity: report.totals.capacityTimeOffAdjusted,
      billableUtilization: report.totals.billableUtilizationTimeOffAdjusted,
      clientUtilization: report.totals.clientUtilizationTimeOffAdjusted,
      productiveUtilization: report.totals.productiveUtilizationTimeOffAdjusted,
      totalUtilization: report.totals.totalUtilizationTimeOffAdjusted,
      targetAttainment: report.totals.targetAttainmentTimeOffAdjusted,
    },
  }[params.utilizationMode];

  return (
    <Report.Table>
      <Table>
        <Table.Header>
          <Table.Column sticky minWidth="16rem" name="name" onSort={onSort} sort={params.sort}>
            Member Practice
          </Table.Column>
          <Table.Column
            name={columns.capacity[params.utilizationMode]}
            width="6.5rem"
            align="right"
            onSort={onSort}
            sort={params.sort}>
            Capacity
          </Table.Column>
          <Table.Column name="billableHours" width="6.5rem" align="right" onSort={onSort} sort={params.sort}>
            Billable Hours
          </Table.Column>
          <Table.Column
            name={columns.billableUtilization[params.utilizationMode]}
            width="7rem"
            align="right"
            onSort={onSort}
            sort={params.sort}>
            Billable Utilization
          </Table.Column>
          <Table.Column
            name={columns.clientUtilization[params.utilizationMode]}
            width="7rem"
            align="right"
            onSort={onSort}
            sort={params.sort}>
            Client Utilization
          </Table.Column>
          <Table.Column
            name={columns.productiveUtilization[params.utilizationMode]}
            width="7rem"
            align="right"
            onSort={onSort}
            sort={params.sort}>
            Productive Utilization
          </Table.Column>
          <Table.Column
            name={columns.totalUtilization[params.utilizationMode]}
            width="7rem"
            align="right"
            onSort={onSort}
            sort={params.sort}>
            Total Utilization
          </Table.Column>
          <Table.Column name="targetBillableUtilization" width="7rem" align="right" onSort={onSort} sort={params.sort}>
            Target Billable Utilization
          </Table.Column>
          <Table.Column
            name={columns.targetAttainment[params.utilizationMode]}
            width="7rem"
            align="right"
            onSort={onSort}
            sort={params.sort}>
            Target Attainment
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.status === 'sorting'}>
          {report.records.map((practice) => {
            const values = {
              default: {
                capacity: practice.capacity,
                billableUtilization: practice.billableUtilization,
                clientUtilization: practice.clientUtilization,
                productiveUtilization: practice.productiveUtilization,
                totalUtilization: practice.totalUtilization,
                targetAttainment: practice.targetAttainment,
              },

              time_off_adjusted: {
                capacity: practice.capacityTimeOffAdjusted,
                billableUtilization: practice.billableUtilizationTimeOffAdjusted,
                clientUtilization: practice.clientUtilizationTimeOffAdjusted,
                productiveUtilization: practice.productiveUtilizationTimeOffAdjusted,
                totalUtilization: practice.totalUtilizationTimeOffAdjusted,
                targetAttainment: practice.targetAttainmentTimeOffAdjusted,
              },
            }[params.utilizationMode];

            return (
              <Table.Row key={practice.practiceId || 'no_member_practice'}>
                <Table.Cell>
                  {practice.practiceId ? practice.name : <NoMemberPractice>No Member Practice</NoMemberPractice>}
                </Table.Cell>
                <Table.Cell>
                  <Hours value={values.capacity} />
                </Table.Cell>
                <Table.Cell>
                  {practice.practiceId ? (
                    <RouteLink to={timeDetail({ billableType: 'billable', memberPractice: practice.practiceId })}>
                      <Hours value={practice.billableHours} />
                    </RouteLink>
                  ) : (
                    <Hours value={practice.billableHours} />
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Percent value={values.billableUtilization} />
                </Table.Cell>
                <Table.Cell>
                  <Percent value={values.clientUtilization} />
                </Table.Cell>
                <Table.Cell>
                  <Percent value={values.productiveUtilization} />
                </Table.Cell>
                <Table.Cell>
                  <Percent value={values.totalUtilization} />
                </Table.Cell>
                <Table.Cell>
                  <Percent value={practice.targetBillableUtilization} />
                </Table.Cell>
                <Table.Cell>
                  <Percent value={values.targetAttainment} />
                </Table.Cell>
              </Table.Row>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>
              <Hours value={totals.capacity} />
            </Table.Cell>
            <Table.Cell>
              {/* This does not click-through because the Time Detail report doesn't support
                  including a subset of members based on member-permissions only. */}
              <Hours value={report.totals.billableHours} />
            </Table.Cell>
            <Table.Cell>
              <Percent value={totals.billableUtilization} />
            </Table.Cell>
            <Table.Cell>
              <Percent value={totals.clientUtilization} />
            </Table.Cell>
            <Table.Cell>
              <Percent value={totals.productiveUtilization} />
            </Table.Cell>
            <Table.Cell>
              <Percent value={totals.totalUtilization} />
            </Table.Cell>
            <Table.Cell>
              <Percent value={report.totals.targetBillableUtilization} />
            </Table.Cell>
            <Table.Cell>
              <Percent value={totals.targetAttainment} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>

        <Table.Status
          total={report.records.filter((p) => p.practiceId).length}
          label="Practice"
          isLoading={query.status !== 'ready'}
          partial={report.partialResults}
        />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const auth = useAuth();
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <YesNoFilter
        icon="filter"
        placeholder="Include members without capacity"
        value={filters.includeMembersWithoutCapacity ? 'yes' : 'no'}
        onChange={({ target: { value } }) => handleFilter({ includeMembersWithoutCapacity: value === 'yes' })}
      />

      <ReportPeriodFilter
        clearable={false}
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      {auth.reports.utilization && <MemberFiltersGroup filters={filters} onChange={handleFilter} />}

      <TimeFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

export default UtilizationByMemberPractice;
