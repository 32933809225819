import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BackLink,
  BillableIcon,
  ClientLink,
  Currency,
  DateTime,
  Inline,
  InternalClientTooltip,
  InvoicedFilter,
  MultiSelect,
  ProjectLink,
  ExportDropdown,
  MarkupIcon,
} from '~/components';
import SingleSelectFilter from '~/components/filters/SingleSelectFilter';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useNumberFormat, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import { PageLoader } from '~/routes/public/pages';
import weights from '~/styles/weights';
import { QuerySort, intervalOptions } from '~/utils';
import { sumBy } from '~/utils/math';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import CurrencyFilter from '~/components/filters/CurrencyFilter';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter';
import Table from '../components/table';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import SavedReportActions from '../SavedReportActions';

const ClientInfo = styled.div`
  display: flex;
`;

const ProjectInfo = styled.div`
  line-height: 1;
  margin-left: 1rem;
`;

const Byline = styled.small`
  display: block;
`;

export default function ScheduledItems({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: {
      table: false,
    },
  });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const [params, setParams] = useState({
    period: null,
    billableType: 'billable',
    itemTypes: [],
    invoiced: 'no',
    currency: workspace.currency,
    sort: new QuerySort('project:client.name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { ...reportsSearchParamsConfig.period, default: intervalOptions.this_month },
        billableType: {
          valid: ['billable', 'non_billable', 'all'],
          default: 'billable',
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },
        itemTypes: {
          multi: true,
          key: 'itemType',
          valid: _.keys(itemTypes),
          serialize: (value) => value?.map((v) => v.key),
          deserialize: (values) => values.map((key) => itemTypes[key]),
        },
        invoiced: {
          valid: ['yes', 'no', 'all'],
          default: 'no',
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('project:client.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      billableTypeId: params.billableType ?? undefined,
      itemTypes: params.itemTypes?.map((v) => v.key),
      invoiced: params.invoiced ?? undefined,
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().scheduledItems(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: {
        table: false,
      },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .scheduledItems(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title>{report.name}</Report.Title>
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />

          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ReportPeriodFilter
          value={params.period}
          onChange={({ target: { value } }) => handleApplyFilters({ period: value })}
        />

        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}

        {!_.isEmpty(params.billableType) && (
          <BillableTypeFilter
            value={params.billableType}
            onChange={({ target: { value } }) => handleApplyFilters({ billableType: value })}
          />
        )}

        {!_.isEmpty(params.itemTypes) && (
          <ItemTypeFilter
            value={params.itemTypes}
            onChange={({ target: { value } }) => handleApplyFilters({ itemTypes: value })}
          />
        )}

        {params.invoiced && (
          <InvoicedFilter value={params.invoiced} onChange={({ value }) => handleApplyFilters({ invoiced: value })} />
        )}

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;
  const { workspace } = useWorkspace();

  const locale = workspace.locale;
  const quantityFormat = useNumberFormat({ locale, minimumFractionDigits: 0 });

  const groups = useMemo(() => {
    return report.records.reduce((a, v) => {
      a[v.project.client.id] = a[v.project.client.id] || { client: v.project.client, projects: {} };

      a[v.project.client.id].projects[v.project.id] = a[v.project.client.id].projects[v.project.id] || {
        project: v.project,
        items: [],
      };

      a[v.project.client.id].projects[v.project.id].items.push(v);

      return a;
    }, {});
  }, [report.records]);

  return (
    <Report.Table>
      <Table>
        <Table.Header>
          <Table.Column sticky minWidth="16rem" name="project:client.name" onSort={onSort} sort={params.sort}>
            Client
          </Table.Column>
          <Table.Column width="12rem" align="right">
            Invoice #
          </Table.Column>
          <Table.Column width="7.5rem">Date</Table.Column>
          <Table.Column minWidth="20rem">Details</Table.Column>
          <Table.Column width="10rem" align="right">
            Amount
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.loading.table}>
          {_.map(groups, ({ client, projects }) => {
            const total = sumBy(
              _.flatMap(projects, (project) => project.items),
              'convertedAmount',
            );

            return (
              <React.Fragment key={client.id}>
                <Table.GroupRow top="3rem">
                  <Table.Cell>
                    <strong>
                      <ClientInfo>
                        <ClientLink client={client} />
                        {client.isInternal && <InternalClientTooltip />}
                      </ClientInfo>
                    </strong>
                  </Table.Cell>

                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell />

                  <Table.Cell>
                    <strong>
                      <Currency value={total} currency={report.currency} />
                    </strong>
                  </Table.Cell>
                </Table.GroupRow>

                {_.map(projects, ({ project, items }) => {
                  const total = sumBy(items, 'amount');
                  const convertedTotal = sumBy(items, 'convertedAmount');

                  return (
                    <React.Fragment key={project.id}>
                      <Table.Row>
                        <Table.Cell>
                          <ProjectInfo>
                            <strong>
                              <ProjectLink project={project} />
                            </strong>
                          </ProjectInfo>
                        </Table.Cell>

                        <Table.Cell />
                        <Table.Cell />
                        <Table.Cell />

                        <Table.Cell>
                          <p>
                            <strong>
                              <Currency value={convertedTotal} currency={report.currency} />
                            </strong>
                            {project.currency !== report.currency && (
                              <Byline>
                                <strong>
                                  <Currency value={total} currency={project.currency} />
                                </strong>
                              </Byline>
                            )}
                          </p>
                        </Table.Cell>
                      </Table.Row>

                      {items.map((item) => {
                        const {
                          id,
                          date,
                          type,
                          details,
                          amount,
                          total,
                          convertedAmount,
                          isBillable,
                          invoice,
                          quantity,
                          convertedRate,
                        } = item;

                        return (
                          <Table.Row key={id}>
                            <Table.Cell>
                              <Inline style={{ marginLeft: '2rem' }}>
                                {total > amount && isBillable ? (
                                  <MarkupIcon total={total} currency={project.currency} spaceRight />
                                ) : (
                                  <BillableIcon value={isBillable} spaceRight />
                                )}
                                {type}
                              </Inline>
                            </Table.Cell>
                            <Table.Cell>{invoice?.number}</Table.Cell>
                            <Table.Cell>
                              <DateTime value={date} />
                            </Table.Cell>
                            {quantity !== null && convertedRate !== null ? (
                              <Table.Cell>
                                <p style={{ whiteSpace: 'pre-wrap' }}>
                                  {details} ({quantityFormat.format(quantity)} @{' '}
                                  <Currency value={convertedRate} currency={report.currency} />)
                                </p>
                              </Table.Cell>
                            ) : (
                              <Table.Cell>
                                <p style={{ whiteSpace: 'pre-wrap' }}>{details}</p>
                              </Table.Cell>
                            )}
                            <Table.Cell>
                              <p>
                                <Currency value={convertedAmount} currency={report.currency} />
                                {project.currency !== report.currency && (
                                  <Byline>
                                    <Currency value={amount} currency={project.currency} />
                                  </Byline>
                                )}
                              </p>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.amount} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>

        <Table.Status
          total={_.size(groups)}
          label="Client"
          isLoading={query.status !== 'ready'}
          partial={report.partialResults}
        />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ReportPeriodFilter
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      <BillableTypeFilter
        value={filters.billableType}
        onChange={({ target: { value } }) => handleFilter({ billableType: value })}
      />

      <ItemTypeFilter
        value={filters.itemTypes}
        onChange={({ target: { value } }) => handleFilter({ itemTypes: value })}
      />

      <InvoicedFilter
        value={filters.invoiced}
        onChange={({ target: { value } }) => handleFilter({ invoiced: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

function BillableTypeFilter(props) {
  return (
    <SingleSelectFilter
      placeholder="Item Billability"
      renderValue={(value) => value.name}
      options={[
        { id: 'billable', name: 'Billable' },
        { id: 'non_billable', name: 'Non-Billable' },
      ]}
      {...props}
    />
  );
}

const itemTypes = {
  fixed_fee_milestone: { key: 'fixed_fee_milestone', name: 'Fixed Fee Milestone' },
  other_item: { key: 'other_item', name: 'Other Item' },
  project_expense_item: { key: 'project_expense_item', name: 'Project Expense Item' },
};

function ItemTypeFilter({ placeholder = 'Item Type', name, value, onChange }) {
  return (
    <MultiSelect.Filter
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.map((v) => v.name).join('; ')}
      options={_.values(itemTypes)}
      onChange={onChange}
    />
  );
}
