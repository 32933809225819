import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BackLink, ClientLink, Currency, DayPickerInput, YesNoFilter, ExportDropdown } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import { PageLoader } from '~/routes/public/pages';
import { weights } from '~/styles';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import CurrencyFilter from '~/components/filters/CurrencyFilter';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import Report from '../components/Report';
import Table from '../components/table';
import SavedReportActions from '../SavedReportActions';

export default function AccountsReceivableAging({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const clientFilters = useClientFilters();

  const [params, setParams] = useState({
    date: null,
    currency: workspace.currency,
    includeClientsWithZeroBalance: false,
    sort: new QuerySort('name', 'asc'),
    ...clientFilters.filters,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        date: {
          deserialize: (value) => {
            if (!moment(value, true).isValid()) {
              value = null;
            }
            return value;
          },
        },
        currency: searchParamsConfig.currency,
        includeClientsWithZeroBalance: {
          default: false,
          serialize: (value) => (value === true ? 'true' : 'false'),
          deserialize: (value) => value === 'true',
        },
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      date: params.date ?? undefined,
      currency: params.currency ?? undefined,
      includeClientsWithZeroBalance: params.includeClientsWithZeroBalance ? 'true' : undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().accountsReceivableAging(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: {
        table: false,
      },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };
  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .accountsReceivableAging(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title>{report.name}</Report.Title>
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        {!_.isEmpty(params.date) && (
          <DayPickerInput
            value={params.date}
            placeholder="As Of"
            clearable={true}
            scope={'day'}
            onChange={(value) => handleApplyFilters({ date: value })}
          />
        )}

        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}

        <YesNoFilter
          icon="filter"
          placeholder="Clients with zero balance"
          value={params.includeClientsWithZeroBalance ? 'yes' : 'no'}
          onChange={({ target: { value } }) => handleApplyFilters({ includeClientsWithZeroBalance: value === 'yes' })}
        />

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;

  return (
    <Report.Table>
      <Table>
        <Table.Header>
          <Table.Column sticky name="name" minWidth="16rem" onSort={onSort} sort={params.sort}>
            Client
          </Table.Column>
          <Table.Column align="right" width="10rem">
            Current
          </Table.Column>
          <Table.Column align="right" width="10rem">
            1-30
          </Table.Column>
          <Table.Column align="right" width="10rem">
            31-60
          </Table.Column>
          <Table.Column align="right" width="10rem">
            61-90
          </Table.Column>
          <Table.Column align="right" width="10rem">
            91+
          </Table.Column>
          <Table.Column align="right" width="10rem">
            Total
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.loading.table}>
          {report.records.map((client) => {
            return (
              <Table.Row key={client.id}>
                <Table.Cell>
                  <ClientLink client={client} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={client.current} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={client['01-30']} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={client['31-60']} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={client['61-90']} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={client['91+']} currency={report.currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={client.total} currency={report.currency} />
                </Table.Cell>
              </Table.Row>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>
              <Currency value={report.total.current} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.total['01-30']} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.total['31-60']} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.total['61-90']} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.total['91+']} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.total.total} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>

        <Table.Status
          total={report.records.length}
          label="Client"
          partial={report.partialResults}
          isLoading={query.status !== 'ready'}
        />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <YesNoFilter
        icon="filter"
        placeholder="Include clients with a zero balance"
        value={filters.includeClientsWithZeroBalance ? 'yes' : 'no'}
        onChange={({ target: { value } }) => handleFilter({ includeClientsWithZeroBalance: value === 'yes' })}
      />

      <DayPickerInput
        style={{ fontSize: '.75rem' }}
        value={filters.date}
        placeholder="As Of"
        clearable={true}
        scope={'day'}
        onChange={(value) => handleFilter({ date: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}
