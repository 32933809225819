import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  BackLink,
  DateTime,
  ExportDropdown,
  Hours,
  PeriodFilter,
  RouteLink,
  Tooltip,
  Widget,
  YesNoFilter,
} from '~/components';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import FilterButton from '~/components/filters/FilterButton';
import MemberFiltersBar from '~/components/filters/MemberFiltersBar';
import MemberFiltersGroup from '~/components/filters/MemberFiltersGroup';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter';
import SingleSelectFilter from '~/components/filters/SingleSelectFilter';
import TaskFiltersBar from '~/components/filters/TaskFiltersBar';
import TaskFiltersGroup from '~/components/filters/TaskFiltersGroup';
import TimeFiltersBar from '~/components/filters/TimeFiltersBar';
import TimeFiltersGroup from '~/components/filters/TimeFiltersGroup';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import {
  useClientFilters,
  useDateTimeFormat,
  useDocumentTitle,
  useFeatures,
  useHoursFormat,
  useMemberFilters,
  useProjectFilters,
  useSearchParams,
  useSearchParamsConfig,
} from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QueryString, dateFormats, intervalOptions, mimeTypes } from '~/utils';
import ExportDialogAsync from '../components/ExportDialogAsync';
import Report from '../components/Report';
import Table from '../components/table';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import SavedReportActions from '../SavedReportActions';

const intervals = {
  get custom() {
    return intervalOptions.custom;
  },

  get past_30_days() {
    return {
      key: 'past_30_days',
      label: 'Past 30 days',
      start: moment().subtract(30, 'days').format(dateFormats.isoDate),
      end: moment().subtract(1, 'day').format(dateFormats.isoDate),
      unit: 'custom',
    };
  },

  get past_60_days() {
    return {
      key: 'past_60_days',
      label: 'Past 60 days',
      start: moment().subtract(60, 'days').format(dateFormats.isoDate),
      end: moment().subtract(1, 'day').format(dateFormats.isoDate),
      unit: 'custom',
    };
  },

  get past_90_days() {
    return {
      key: 'past_90_days',
      label: 'Past 90 days',
      start: moment().subtract(90, 'days').format(dateFormats.isoDate),
      end: moment().subtract(1, 'day').format(dateFormats.isoDate),
      unit: 'custom',
    };
  },

  get past_4_weeks() {
    return {
      key: 'past_4_weeks',
      label: 'Past 4 weeks',
      start: moment().subtract(4, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().subtract(1, 'week').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },

  get past_12_weeks() {
    return {
      key: 'past_12_weeks',
      label: 'Past 12 weeks',
      start: moment().subtract(12, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().subtract(1, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },

  get past_26_weeks() {
    return {
      key: 'past_26_weeks',
      label: 'Past 26 weeks',
      start: moment().subtract(26, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().subtract(1, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },

  get past_3_months() {
    return {
      key: 'past_3_months',
      label: 'Past 3 months',
      start: moment().subtract(3, 'months').startOf('month').format(dateFormats.isoDate),
      end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },

  get past_6_months() {
    return {
      key: 'past_6_months',
      label: 'Past 6 months',
      start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
      end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },

  get past_9_months() {
    return {
      key: 'past_9_months',
      label: 'Past 9 months',
      start: moment().subtract(9, 'months').startOf('month').format(dateFormats.isoDate),
      end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },

  get past_12_months() {
    return {
      key: 'past_12_months',
      label: 'Past 12 months',
      start: moment().subtract(12, 'months').startOf('month').format(dateFormats.isoDate),
      end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },
};

const intervalsByScope = {
  get day() {
    return [intervals.custom, intervals.past_30_days, intervals.past_60_days, intervals.past_90_days];
  },

  get week() {
    return [intervals.custom, intervals.past_4_weeks, intervals.past_12_weeks, intervals.past_26_weeks];
  },

  get month() {
    return [
      intervals.custom,
      intervals.past_3_months,
      intervals.past_6_months,
      intervals.past_9_months,
      intervals.past_12_months,
    ];
  },
};

function TimeSummaryByMemberByTimeUnit({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    unit: '',

    // Time Filters
    createdPeriod: null,
    modifiedPeriod: null,
    approvalStatuses: [],
    clientApprovalStatuses: [],
    billableTypes: [],
    timeOffTypes: [],
    invoiced: null,
    projectTasks: [],
    projectTaskStatuses: [],
    projectTaskRecordStatusId: null,
    projectTaskTags: [],
    projectRoles: [],
    roleDisciplines: [],
    includeMembersWithoutTime: false,

    // Member Filters
    ...memberFilters.filters,

    // Client Filters
    ...clientFilters.filters,

    // Project filters
    ...projectFilters.filters,
  });

  const searchParamsConfig = useSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          ...searchParamsConfig.periodByUnit,
          default: intervals.past_6_months,
          intervals: (unit) => intervalsByScope[unit],
          defaults: {
            day: intervals.past_30_days,
            week: intervals.past_12_weeks,
            month: intervals.past_6_months,
          },
        },
        unit: { default: 'month', valid: ['day', 'week', 'month'] },

        // Time Filters
        createdPeriod: reportsSearchParamsConfig.createdPeriod,
        modifiedPeriod: reportsSearchParamsConfig.modifiedPeriod,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        billableTypes: searchParamsConfig.timeBillableTypes,
        timeOffTypes: searchParamsConfig.timeOffTypes,
        invoiced: searchParamsConfig.invoiced,
        projectTasks: searchParamsConfig.projectTasks,
        projectTaskStatuses: searchParamsConfig.projectTaskStatuses,
        projectTaskRecordStatusId: searchParamsConfig.recordStatusId,
        projectTaskTags: searchParamsConfig.projectTaskTags,
        roleDisciplines: { ...searchParamsConfig.disciplines, key: 'roleDiscipline' },
        projectRoles: searchParamsConfig.projectRoles,
        includeMembersWithoutTime: {
          default: false,
          serialize: (value) => (value === true ? 'true' : 'false'),
          deserialize: (value) => value === 'true',
        },

        // Member Filters
        ...memberFilters.searchParamsConfig,

        // Client Filters
        ...clientFilters.searchParamsConfig,

        // Project Filters
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      unit: params.unit,

      // Time Filters
      createdStart: params.createdPeriod?.start ?? undefined,
      createdEnd: params.createdPeriod?.end ?? undefined,
      modifiedStart: params.modifiedPeriod?.start ?? undefined,
      modifiedEnd: params.modifiedPeriod?.end ?? undefined,
      statusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      billableTypeId: params.billableTypes?.map((v) => v.id),
      timeOffTypeId: params.timeOffTypes?.map((v) => v.id),
      invoiced: params.invoiced ?? undefined,
      projectTaskId: params.projectTasks?.map((v) => v.id),
      projectTaskStatusId: params.projectTaskStatuses?.map((v) => v.id),
      projectTaskRecordStatusId: params.projectTaskRecordStatusId ?? undefined,
      projectTaskTagId: params.projectTaskTags?.map((v) => v.id),
      roleDisciplineId: params.roleDisciplines?.map((v) => v.id),
      projectRoleId: params.projectRoles?.map((v) => v.id),
      includeMembersWithoutTime: params.includeMembersWithoutTime ? 'true' : undefined,

      // Member Filters
      ...memberFilters.mapUrlSearchParams(params),

      // Client Filters
      ...clientFilters.mapUrlSearchParams(params),

      // Project Filters
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, features.clientApprovals, clientFilters, projectFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().timeSummaryByMemberByTimeUnit(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(values);
    hideFilters();
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .timeSummaryByMemberByTimeUnit(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title>{report.name}</Report.Title>
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ReportPeriodFilter
          value={params.period}
          intervals={intervalsByScope[params.unit]}
          onChange={({ value }) => handleApplyFilters({ period: value })}
        />

        <UnitFilter
          value={params.unit}
          onChange={({ target: { value } }) =>
            handleApplyFilters({
              unit: value,
              period: {
                day: intervals.past_30_days,
                week: intervals.past_12_weeks,
                month: intervals.past_6_months,
              }[value],
            })
          }
        />

        <YesNoFilter
          icon="filter"
          placeholder="Include members without time"
          value={params.includeMembersWithoutTime ? 'yes' : 'no'}
          onChange={({ target: { value } }) => handleApplyFilters({ includeMembersWithoutTime: value === 'yes' })}
        />

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />
        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
        <TaskFiltersBar filters={params} onChange={handleApplyFilters} />
        <MemberFiltersBar filters={params} onChange={handleApplyFilters} />
        <TimeFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params }) {
  const report = query.data;

  const { members, records, periods, start, end } = useMemo(() => {
    const members = _.orderBy(report.members, (m) => m.name.toLowerCase());

    const records = report.records.reduce(
      (a, v) => {
        a.total.billable += v.billable || 0;
        a.total.nonBillable += v.nonBillable || 0;
        a.total.internal += v.internal || 0;
        a.total.timeOff += v.timeOff || 0;
        a.total.total += v.total || 0;

        // Members
        a.members[v.memberId] = a.members[v.memberId] || {
          total: {
            billable: 0,
            nonBillable: 0,
            internal: 0,
            timeOff: 0,
            total: 0,
          },
          periods: {},
        };
        a.members[v.memberId].total.billable += v.billable || 0;
        a.members[v.memberId].total.nonBillable += v.nonBillable || 0;
        a.members[v.memberId].total.internal += v.internal || 0;
        a.members[v.memberId].total.timeOff += v.timeOff || 0;
        a.members[v.memberId].total.total += v.total || 0;

        a.members[v.memberId].periods[v.start] = v;

        // Periods
        if (!a.periods[v.start])
          a.periods[v.start] = {
            start: v.start,
            end: v.end,
            billable: 0,
            nonBillable: 0,
            internal: 0,
            timeOff: 0,
            total: 0,
          };
        a.periods[v.start].billable += v.billable || 0;
        a.periods[v.start].nonBillable += v.nonBillable || 0;
        a.periods[v.start].internal += v.internal || 0;
        a.periods[v.start].timeOff += v.timeOff || 0;
        a.periods[v.start].total += v.total || 0;

        return a;
      },
      {
        members: {},
        periods: {},
        total: {
          billable: 0,
          nonBillable: 0,
          internal: 0,
          timeOff: 0,
          total: 0,
        },
      },
    );

    let start = params.period.start;
    let end = params.period.end;

    switch (params.unit) {
      case 'day':
        start = moment(start).format(dateFormats.isoDate);
        end = moment(end).format(dateFormats.isoDate);
        break;

      case 'week':
        start = moment(start).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(end).endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'month':
        start = moment(start).startOf('month').format(dateFormats.isoDate);
        end = moment(end).endOf('month').format(dateFormats.isoDate);
        break;
    }

    const periodCount = moment(end).diff(start, params.unit) + 1;
    const periods = [];
    for (let index = 0; index < periodCount; index++) {
      const periodStart = moment(start).add(index, params.unit).format(dateFormats.isoDate);
      const periodEnd = moment(periodStart)
        .endOf({ day: 'day', week: 'isoWeek', month: 'month' }[params.unit])
        .format(dateFormats.isoDate);
      periods.push({ start: periodStart, end: periodEnd });
    }

    return { members, records, periods, start, end };
  }, [report, params.period.start, params.period.end, params.unit]);

  const { workspace } = useWorkspace();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time-entries?${new QueryString(
        {
          start,
          end,
          projectRole: params.projectRoles?.map((v) => v.id),
          projectTask: params.projectTasks?.map((v) => v.id),
          projectTaskStatus: params.projectTaskStatuses?.map((v) => v.id),
          projectTaskRecordStatusId: params.projectTaskRecordStatusId ?? undefined,
          projectTaskTag: params.projectTaskTags?.map((v) => v.id),
          status: params.approvalStatuses?.map((v) => v.id),
          clientStatus: params.clientApprovalStatuses?.map((v) => v.id),
          invoiced: params.invoiced ?? undefined,
          roleDiscipline: params.roleDisciplines?.map((v) => v.id),
          billableType: params.billableTypes?.map((v) => v.id),
          timeOffType: params.timeOffTypes?.map((v) => v.id),
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...memberFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters, memberFilters, start, end],
  );

  const history = useHistory();

  const handleChartClick = ({ start, end, billableType }) => {
    history.push(timeDetail({ start, end, billableType }));
  };

  return (
    <>
      <Report.Summary>
        <Chart periods={periods} records={records} unit={params.unit} onClick={handleChartClick} />
      </Report.Summary>

      <Report.Table>
        <Table>
          <Table.Header>
            <Table.Column sticky minWidth="16rem">
              Member
            </Table.Column>

            {periods.map(({ start }) => (
              <Table.Column key={start} align="right" width="7rem">
                {
                  {
                    day: <DateTime value={start} />,
                    week: <DateTime value={start} />,
                    month: moment(start).format(dateFormats.monthYear),
                  }[params.unit]
                }
              </Table.Column>
            ))}

            <Table.Column align="right" width="7rem">
              Total
            </Table.Column>
          </Table.Header>

          <Table.Body>
            {members.map((member) => {
              return (
                <Table.Row key={member.id}>
                  <Table.Cell>{member.name}</Table.Cell>

                  {periods.map((period) => {
                    const value = records.members[member.id]?.periods[period.start];

                    return (
                      <Table.Cell key={period.start}>
                        <CellValue
                          value={value}
                          to={timeDetail({
                            member: member.id,
                            start: period.start,
                            end: period.end,
                          })}
                        />
                      </Table.Cell>
                    );
                  })}

                  <Table.Cell>
                    <CellValue value={records.members[member.id]?.total} to={timeDetail({ member: member.id })} />
                  </Table.Cell>
                </Table.Row>
              );
            })}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell>Total</Table.Cell>

              {periods.map((period) => {
                const value = records.periods[period.start];

                return (
                  <Table.Cell key={period.start}>
                    <CellValue value={value} to={timeDetail({ start: period.start, end: period.end })} />
                  </Table.Cell>
                );
              })}

              <Table.Cell>
                <CellValue value={records.total} to={timeDetail()} />
              </Table.Cell>
            </Table.Row>
          </Table.Body>

          <Table.Status total={members.length} label="Member" isLoading={query.status !== 'ready'} />
        </Table>
      </Report.Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <YesNoFilter
        icon="filter"
        placeholder="Include members without tracked time"
        value={filters.includeMembersWithoutTime ? 'yes' : 'no'}
        onChange={({ target: { value } }) => handleFilter({ includeMembersWithoutTime: value === 'yes' })}
      />

      <UnitFilter
        value={filters.unit}
        onChange={({ target: { value } }) =>
          handleFilter({
            unit: value,
            period: {
              day: intervals.past_30_days,
              week: intervals.past_12_weeks,
              month: intervals.past_6_months,
            }[value],
          })
        }
      />

      <PeriodFilter
        clearable={false}
        scope={filters.unit}
        intervals={intervalsByScope[filters.unit]}
        placeholder="Date Range"
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <TaskFiltersGroup filters={filters} onChange={handleFilter} />

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />

      <TimeFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

const TooltipContainer = styled.div`
  min-width: 14rem;
  padding: 0.75rem 0;
`;

const TooltipInfo = styled.div`
  display: flex;
  font-size: 0.75rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

const TooltipLabel = styled.div`
  width: 15rem;
  padding-right: 0.5rem;
  color: ${colors.grey40};
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  flex-shrink: 0;
  white-space: wrap;
`;

const TooltipValue = styled.div`
  white-space: wrap;
`;

function TooltipHours({ value }) {
  return (
    <>
      <Hours value={value} /> {_.isNumber(value) && pluralize('hours', value)}
    </>
  );
}

function CellValue({ value, to }) {
  if (!value)
    return to ? (
      <RouteLink to={to}>
        <Hours value={null} />
      </RouteLink>
    ) : (
      <Hours value={null} />
    );

  return (
    <Tooltip
      maxWidth="22rem"
      message={
        <TooltipContainer>
          <TooltipInfo>
            <TooltipLabel>Client Billable</TooltipLabel>
            <TooltipValue>
              <TooltipHours value={value.billable} />
            </TooltipValue>
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Client Non-Billable</TooltipLabel>
            <TooltipHours value={value.nonBillable} />
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Internal</TooltipLabel>
            <TooltipHours value={value.internal} />
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Time Off</TooltipLabel>
            <TooltipHours value={value.timeOff} />
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Total</TooltipLabel>
            <TooltipHours value={value.total} />
          </TooltipInfo>
        </TooltipContainer>
      }>
      {to ? (
        <RouteLink to={to}>
          <Hours value={value.total} />
        </RouteLink>
      ) : (
        <Hours value={value.total} />
      )}
    </Tooltip>
  );
}

const getMax = (periods, defaultMax = 8, precision = 2) => {
  let max = Math.max(...periods.map(({ total }) => Math.round(total) || 0), defaultMax) * 1.1;
  max = Math.floor(max / precision) * precision + precision;

  return max;
};

function Chart({ periods, records, unit, onClick }) {
  const dateTimeFormat = useDateTimeFormat(dateFormats.compactDate);

  const { settings, datasets } = useMemo(() => {
    const data = periods.map((period) => records.periods[period.start] ?? period);

    const settings = {
      day: () => ({
        labels: data.map(({ start }) => dateTimeFormat.format(start)),
        max: getMax(data, 8, 2),
        stepSize: 2,
      }),
      week: () => ({
        labels: data.map(({ start }) => dateTimeFormat.format(start)),
        max: getMax(data, 8, 2),
        stepSize: 10,
      }),
      month: () => ({
        labels: data.map(({ start }) => moment(start).format(dateFormats.monthYear)),
        max: getMax(data, 8, 2),
        stepSize: 20,
      }),
    }[unit]();

    const datasets = [
      {
        id: 'billable',
        label: 'Client Billable    ',
        data: data.map(({ billable }) => billable),
        backgroundColor: colors.success,
      },
      {
        id: 'non-billable',
        label: 'Client Non-Billable    ',
        data: data.map(({ nonBillable }) => nonBillable),
        backgroundColor: colors.danger,
      },
      {
        id: 'internal',
        label: 'Internal    ',
        data: data.map(({ internal }) => internal),
        backgroundColor: colors.warning,
      },
      {
        id: 'time-off',
        label: 'Time Off    ',
        data: data.map(({ timeOff }) => timeOff),
        backgroundColor: colors.primary50,
      },
      {
        id: 'total',
        label: 'Total',
        data: data.map(({ total }) => (total ? 0 : 0.25)),
        backgroundColor: colors.grey5,
      },
    ];

    return { settings, datasets };
  }, [periods, records, unit, dateTimeFormat]);

  const hoursFormat = {
    tooltip: useHoursFormat({ minimumFractionDigits: 0, maximumFractionDigits: 2 }),
  };

  const handleClick = (_event, [element]) => {
    if (!element) return;

    const billableTypes = ['billable', 'non_billable', 'internal', 'time_off'];

    const billableType = billableTypes[element.datasetIndex];
    const { start, end } = periods[element.index];

    onClick({ start, end, billableType });
  };

  return (
    <Widget style={{ height: '20rem' }}>
      <Bar
        data={{
          labels: settings.labels,
          datasets: datasets,
        }}
        options={{
          maintainAspectRatio: false,

          layout: {
            padding: { top: 12 },
          },

          plugins: {
            legend: {
              onClick: null,
              labels: {
                font: { size: 12 },
                pointStyleWidth: 14,
                boxHeight: 10,
                filter: (item) => !item.text.includes('Total'),
                usePointStyle: true,
              },
            },

            tooltip: {
              filter: (item) => item.datasetIndex <= 3,
              callbacks: {
                title: () => '',
                label: (tooltip) => {
                  let label = (tooltip.dataset.label || '').trim();
                  if (label) {
                    label += ': ';
                  }
                  label += hoursFormat.tooltip.format(tooltip.parsed.y);
                  return label;
                },
              },
            },
          },

          scales: {
            x: {
              stacked: true,
              grid: { display: false },
              ticks: {
                font: {
                  size: 12,
                  weight: 'bold',
                },
                color: colors.grey40,
                minRotation: settings.rotation,
                maxRotation: settings.rotation,
              },
            },

            y: {
              display: true,
              stacked: true,
              grid: { display: true, color: colors.grey10 },
              border: { display: false },
              max: settings.max,
              ticks: { stepSize: settings.stepSize, color: colors.grey40 },
            },
          },

          onHover: (e, chartElement) => {
            e.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
          },

          onClick: handleClick,
        }}
      />
    </Widget>
  );
}

function UnitFilter(props) {
  return (
    <SingleSelectFilter
      icon="gear"
      placeholder="Unit"
      clearable={false}
      renderValue={(value) => value.name}
      options={[
        { id: 'month', name: 'Month' },
        { id: 'week', name: 'Week' },
        { id: 'day', name: 'Day' },
      ]}
      {...props}
    />
  );
}

export default TimeSummaryByMemberByTimeUnit;
