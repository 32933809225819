import Big from 'big.js';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  BackLink,
  ClientLink,
  Currency,
  DateTime,
  InternalClientTooltip,
  ProjectLink,
  ExportDropdown,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import projectBillingTypes from '~/lookups/project-billing-types.js';
import revenueRecognitionMethods from '~/lookups/revenue-recognition-methods';
import ExpenseItemDrawer from '~/routes/app/expenses/item/ExpenseItemDrawer';
import ProjectDrawer from '~/routes/app/projects/project-drawer/ProjectDrawer';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { intervalOptions } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import CurrencyFilter from '~/components/filters/CurrencyFilter';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter';
import Table from '../components/table';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import SavedReportActions from '../SavedReportActions';

const ClientInfo = styled.div`
  display: flex;
`;

const ProjectInfo = styled.div`
  line-height: 1;
  margin-left: 1rem;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    padding-top: 0.25rem;
  }
`;

const Byline = styled.small`
  display: block;
`;

export default function FixedFeeRevenue({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectBillingTypes: {
      ...searchParamsConfig.projectBillingTypes,
      default: [projectBillingTypes.fixed, projectBillingTypes.fixed_recurring],
    },
    projectRecordStatusId: { ...searchParamsConfig.recordStatusId, default: 'active' },
  }));

  const [params, setParams] = useState({
    period: null,
    currency: workspace.currency,
    ...projectFilters.filters,
    ...clientFilters.filters,
  });

  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { ...reportsSearchParamsConfig.period, default: intervalOptions.this_month },
        currency: searchParamsConfig.currency,
        ...projectFilters.searchParamsConfig,
        ...clientFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      currency: params.currency ?? undefined,
      ...projectFilters.mapUrlSearchParams(params),
      ...clientFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().fixedFeeRevenue(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: {
        table: false,
      },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(values);
    hideFilters();
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .fixedFeeRevenue(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title>{report.name}</Report.Title>
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ReportPeriodFilter
          value={params.period}
          onChange={({ target: { value } }) => handleApplyFilters({ period: value })}
        />

        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onReload={fetchData} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, onReload }) {
  const documentTitle = useDocumentTitle();
  const [dialog, setDialog] = useState(null);
  const handleCloseDialog = () => {
    setDialog(null);
    documentTitle.set('Fixed Fee Services Revenue by Client and Project');
  };
  const route = useRouteMatch();
  const history = useHistory();
  const report = query.data;

  const clients = useMemo(() => {
    const projectsById = _.keyBy(report.projects, 'id');

    const clients = _.orderBy(report.records, (r) => [
      projectsById[r.projectId].client.name.toLowerCase(),
      projectsById[r.projectId].name.toLowerCase(),
    ]).reduce((a, v) => {
      const project = projectsById[v.projectId];
      const client = project.client;

      a[client.id] = a[client.id] || {
        client: project.client,
        projects: {},
        convertedInvoiced: 0,
        convertedUninvoiced: 0,
        convertedRecognized: 0,
        convertedUnrecognized: 0,
      };

      a[client.id].convertedInvoiced = Big(a[client.id].convertedInvoiced)
        .add(v.convertedInvoiced || 0)
        .toNumber();

      a[client.id].convertedUninvoiced = Big(a[client.id].convertedUninvoiced)
        .add(v.convertedUninvoiced || 0)
        .toNumber();

      a[client.id].convertedRecognized = Big(a[client.id].convertedRecognized)
        .add(v.convertedRecognized || 0)
        .toNumber();

      a[client.id].convertedUnrecognized = Big(a[client.id].convertedUnrecognized)
        .add(v.convertedUnrecognized || 0)
        .toNumber();

      a[client.id].projects[project.id] = a[client.id].projects[project.id] || {
        project,
        dates: [],
        invoiced: 0,
        uninvoiced: 0,
        recognized: 0,
        unrecognized: 0,
        convertedInvoiced: 0,
        convertedUninvoiced: 0,
        convertedRecognized: 0,
        convertedUnrecognized: 0,
      };

      a[client.id].projects[project.id].invoiced = Big(a[client.id].projects[project.id].invoiced)
        .add(v.invoiced || 0)
        .toNumber();

      a[client.id].projects[project.id].uninvoiced = Big(a[client.id].projects[project.id].uninvoiced)
        .add(v.uninvoiced || 0)
        .toNumber();

      a[client.id].projects[project.id].recognized = Big(a[client.id].projects[project.id].recognized)
        .add(v.recognized || 0)
        .toNumber();

      a[client.id].projects[project.id].unrecognized = Big(a[client.id].projects[project.id].unrecognized)
        .add(v.unrecognized || 0)
        .toNumber();

      a[client.id].projects[project.id].convertedInvoiced = Big(a[client.id].projects[project.id].convertedInvoiced)
        .add(v.convertedInvoiced || 0)
        .toNumber();

      a[client.id].projects[project.id].convertedUninvoiced = Big(a[client.id].projects[project.id].convertedUninvoiced)
        .add(v.convertedUninvoiced || 0)
        .toNumber();

      a[client.id].projects[project.id].convertedRecognized = Big(a[client.id].projects[project.id].convertedRecognized)
        .add(v.convertedRecognized || 0)
        .toNumber();

      a[client.id].projects[project.id].convertedUnrecognized = Big(
        a[client.id].projects[project.id].convertedUnrecognized,
      )
        .add(v.convertedUnrecognized || 0)
        .toNumber();

      a[client.id].projects[project.id].dates.push(v);

      return a;
    }, {});

    return clients;
  }, [report.records, report.projects]);

  return (
    <>
      <Report.Table>
        <Table>
          <Table.Header>
            <Table.Column sticky minWidth="16rem">
              Client
            </Table.Column>
            <Table.Column width="7.5rem">Date</Table.Column>
            <Table.Column width="10rem" align="right">
              Invoiced Services Revenue
            </Table.Column>
            <Table.Column width="10rem" align="right">
              Uninvoiced Services Revenue
            </Table.Column>
            <Table.Column width="10rem" align="right">
              Recognized Services Revenue
            </Table.Column>
            <Table.Column width="10rem" align="right">
              Unrecognized Services Revenue
            </Table.Column>
          </Table.Header>

          <Table.Body fade={query.loading.table}>
            {_.map(
              clients,
              ({
                client,
                convertedInvoiced,
                convertedUninvoiced,
                convertedRecognized,
                convertedUnrecognized,
                projects,
              }) => {
                return (
                  <React.Fragment key={client.id}>
                    <Table.GroupRow top="4rem" style={{ fontWeight: weights.bold }}>
                      <Table.Cell>
                        <ClientInfo>
                          <ClientLink client={client} />
                          {client.isInternal && <InternalClientTooltip />}
                        </ClientInfo>
                      </Table.Cell>
                      <Table.Cell />
                      <Table.Cell>
                        <Currency value={convertedInvoiced} currency={report.currency} />
                      </Table.Cell>
                      <Table.Cell>
                        <Currency value={convertedUninvoiced} currency={report.currency} />
                      </Table.Cell>
                      <Table.Cell>
                        <Currency value={convertedRecognized} currency={report.currency} />
                      </Table.Cell>
                      <Table.Cell>
                        <Currency value={convertedUnrecognized} currency={report.currency} />
                      </Table.Cell>
                    </Table.GroupRow>

                    {_.map(
                      projects,
                      ({
                        project,
                        invoiced,
                        uninvoiced,
                        recognized,
                        unrecognized,
                        convertedInvoiced,
                        convertedUninvoiced,
                        convertedRecognized,
                        convertedUnrecognized,
                        dates,
                      }) => {
                        return (
                          <React.Fragment key={project.id}>
                            <Table.Row>
                              <Table.Cell>
                                <ProjectInfo>
                                  <p>
                                    <ProjectLink project={project} />
                                  </p>
                                  <small>
                                    Recognition Method:{' '}
                                    {revenueRecognitionMethods[project.revenueRecognitionMethod].name}
                                  </small>
                                </ProjectInfo>
                              </Table.Cell>
                              <Table.Cell />
                              <Table.Cell>
                                <p>
                                  <strong>
                                    <Currency value={convertedInvoiced} currency={report.currency} />
                                  </strong>
                                  {project.currency !== report.currency && (
                                    <Byline>
                                      <strong>
                                        <Currency value={invoiced} currency={project.currency} />
                                      </strong>
                                    </Byline>
                                  )}
                                </p>
                              </Table.Cell>
                              <Table.Cell>
                                <p>
                                  <strong>
                                    <Currency value={convertedUninvoiced} currency={report.currency} />
                                  </strong>
                                  {project.currency !== report.currency && (
                                    <Byline>
                                      <strong>
                                        <Currency value={uninvoiced} currency={project.currency} />
                                      </strong>
                                    </Byline>
                                  )}
                                </p>
                              </Table.Cell>
                              <Table.Cell>
                                <p>
                                  <strong>
                                    <Currency value={convertedRecognized} currency={report.currency} />
                                  </strong>
                                  {project.currency !== report.currency && (
                                    <Byline>
                                      <strong>
                                        <Currency value={recognized} currency={project.currency} />
                                      </strong>
                                    </Byline>
                                  )}
                                </p>
                              </Table.Cell>
                              <Table.Cell>
                                <p>
                                  <strong>
                                    <Currency value={convertedUnrecognized} currency={report.currency} />
                                  </strong>
                                  {project.currency !== report.currency && (
                                    <Byline>
                                      <strong>
                                        <Currency value={unrecognized} currency={project.currency} />
                                      </strong>
                                    </Byline>
                                  )}
                                </p>
                              </Table.Cell>
                            </Table.Row>

                            {_.map(
                              dates,
                              ({
                                id,
                                type,
                                typeKey,
                                date,
                                invoiced,
                                uninvoiced,
                                recognized,
                                unrecognized,
                                convertedInvoiced,
                                convertedUninvoiced,
                                convertedRecognized,
                                convertedUnrecognized,
                              }) => {
                                let handleClick;

                                switch (typeKey) {
                                  case 'expense_item': {
                                    handleClick = () => {
                                      history.push({
                                        pathname: `${route.url}/${id}`,
                                        search: location.search,
                                        state: { scrollToTop: false },
                                      });
                                    };

                                    break;
                                  }

                                  case 'fixed_fee_milestone':
                                  case 'revenue_recognition_entry':
                                  case 'project_expense_item': {
                                    if (project.permissions.edit) {
                                      handleClick = () =>
                                        setDialog(
                                          <ProjectDrawer
                                            projectId={project.id}
                                            selectedTabIndex={3}
                                            onSaved={onReload}
                                            onDeleted={onReload}
                                            onClose={handleCloseDialog}
                                          />,
                                        );
                                    }

                                    break;
                                  }
                                }

                                return (
                                  <Table.Row key={id} onClick={handleClick}>
                                    <Table.Cell>
                                      <div style={{ marginLeft: '2rem' }}>{type}</div>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <DateTime value={date} />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <p>
                                        <Currency value={convertedInvoiced} currency={report.currency} />
                                        {invoiced && project.currency !== report.currency && (
                                          <Byline>
                                            <Currency value={invoiced} currency={project.currency} />
                                          </Byline>
                                        )}
                                      </p>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <p>
                                        <Currency value={convertedUninvoiced} currency={report.currency} />
                                        {uninvoiced && project.currency !== report.currency && (
                                          <Byline>
                                            <Currency value={uninvoiced} currency={project.currency} />
                                          </Byline>
                                        )}
                                      </p>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <p>
                                        <Currency value={convertedRecognized} currency={report.currency} />
                                        {recognized && project.currency !== report.currency && (
                                          <Byline>
                                            <Currency value={recognized} currency={project.currency} />
                                          </Byline>
                                        )}
                                      </p>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <p>
                                        <Currency value={convertedUnrecognized} currency={report.currency} />
                                        {unrecognized && project.currency !== report.currency && (
                                          <Byline>
                                            <Currency value={unrecognized} currency={project.currency} />
                                          </Byline>
                                        )}
                                      </p>
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              },
                            )}
                          </React.Fragment>
                        );
                      },
                    )}
                  </React.Fragment>
                );
              },
            )}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <Currency value={report.totals.invoiced} currency={report.currency} />
              </Table.Cell>
              <Table.Cell>
                <Currency value={report.totals.uninvoiced} currency={report.currency} />
              </Table.Cell>
              <Table.Cell>
                <Currency value={report.totals.recognized} currency={report.currency} />
              </Table.Cell>
              <Table.Cell>
                <Currency value={report.totals.unrecognized} currency={report.currency} />
              </Table.Cell>
            </Table.Row>
          </Table.Body>

          <Table.Status
            total={_.size(clients)}
            label="Client"
            isLoading={query.status !== 'ready'}
            partial={report.partialResults}
          />
        </Table>
      </Report.Table>

      {dialog}

      <Route path={route.path.concat('/:expenseItemId')}>
        <ExpenseItemDrawer
          onSaved={onReload}
          onDeleted={onReload}
          onClose={() => {
            history.push({ pathname: route.url, search: location.search, state: { scrollToTop: false } });
            documentTitle.set('Uninvoiced Revenue');
          }}
        />
      </Route>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ReportPeriodFilter
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}
