import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  Button,
  Buttons,
  CancelButton,
  Checkbox,
  Currency,
  DateTime,
  DeleteButton,
  DeleteConfirmation,
  Field,
  Form,
  HelpTooltip,
  Icon,
  InlineTooltip,
  Level,
  ModalCard,
  Table,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';
import { dateFormats, emptyStringToNull, mergeValues } from '~/utils';
import GenerateRevRecEntries from './GenerateRevRecEntries';
import { Header } from './Header';
import { TableTitle } from './TableTitle';

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
  }
`;

const ContainerControl = styled.span`
  display: flex;
  align-items: center;
`;

const UL = styled.ul`
  margin-top: 0.5rem;
  list-style: disc;

  li {
    margin-left: 1rem;
    &:not(:first-child) {
      padding-top: 0.25rem;
    }
  }
`;

function RevenueRecognitionLedger({ project, formik, projectModel, onChange, onRefetch }) {
  const [formEntry, setEditForm] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const handleAdd = () => setEditForm({});
  const handleEdit = (entry) => setEditForm(entry);
  const handleCancel = () => setEditForm(null);

  const selected = useMemo(() => {
    return {
      get items() {
        return this.available.filter((item) => selectedIds.some((id) => id === item.id));
      },
      get some() {
        return this.items.length > 0;
      },
      get available() {
        return projectModel.revenueRecognitionEntries;
      },
      get all() {
        return this.items.length === projectModel.revenueRecognitionEntries.length;
      },
    };
  }, [selectedIds, projectModel.revenueRecognitionEntries]);

  const handleBulkSelectionChange = () => {
    setSelectedIds(
      selected.some ? [] : [...selectedIds, ...projectModel.revenueRecognitionEntries.map(({ id }) => id)],
    );
  };

  const handleSubmit = (value) => {
    value = emptyStringToNull(value);

    const entries = value.id
      ? projectModel.revenueRecognitionEntries.map((rre) => (rre.id === value.id ? { ...rre, ...value } : rre))
      : [...projectModel.revenueRecognitionEntries, { ...value, id: _.uniqueId('rre_') }];

    onChange(entries);
    handleCancel();
  };

  const handleDelete = (item) => {
    onChange(projectModel.revenueRecognitionEntries.filter((r) => r.id !== item.id));
  };

  const handleDeleteMultiple = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>
        This will remove the selected revenue recognition ledger entries from the project. Are you sure?
      </DeleteConfirmation>
    ));
    if (!confirm) return;
    onChange(projectModel.revenueRecognitionEntries.filter((item) => !selectedIds.includes(item.id)));
  };

  const total = {
    recognized: _.round(_.sumBy(projectModel.revenueRecognitionEntries, 'amount'), 2),
    scheduled: _.round(_.sumBy(projectModel.invoiceMilestones, 'fee'), 2),

    get remaining() {
      return _.round(this.scheduled - this.recognized, 2);
    },
  };

  const confirmation = useConfirmation();
  const currency = projectModel.currency;

  const handleAddMultiple = async () => {
    await confirmation.prompt((resolve) => {
      const handleSubmit = (values) => {
        const revenueRecognitionEntries = [
          ...projectModel.revenueRecognitionEntries,
          ...values.map((rr) => ({ ...rr, id: _.uniqueId('rre_') })),
        ];
        onChange(revenueRecognitionEntries);
        resolve(true);
      };

      return (
        <MultipleRevenueRecognitionEntriesForm
          project={projectModel}
          currency={currency}
          onSubmit={handleSubmit}
          onCancel={() => resolve(false)}
        />
      );
    });
  };

  const handleGenerate = async () => {
    await confirmation.prompt((resolve) => (
      <GenerateRevRecEntries onClose={resolve} onRefetch={onRefetch} projectModel={projectModel} />
    ));
  };

  const generateButtonStatus = useMemo(() => {
    let errors = [];

    if (formik.dirty) {
      errors.push('To generate the revenue recognition ledger, save the form changes.');
    } else {
      if (!project.useBudget) errors.push('Project must use budget.');
      if (!project.budgetServicesRevenue) errors.push('Project must have a services revenue budget.');
      if (!project.budgetBillableHours) errors.push('Project must have a billable hours budget.');
    }

    return {
      disabled: errors.length > 0,
      errors,
    };
  }, [project, formik.dirty]);

  return (
    <>
      <Header>
        <Level>
          <Level.Item>
            <TableTitle>
              Revenue Recognition Ledger
              <HelpTooltip
                message="Schedule the recognition of the fixed fee project revenue below."
                style={{ marginLeft: '0.5rem' }}
              />
            </TableTitle>
          </Level.Item>

          <Level.Item right narrow>
            <div style={{ position: 'relative' }}>
              <Button isOutline disabled={generateButtonStatus.disabled} onClick={handleGenerate}>
                Generate
                {generateButtonStatus.disabled && (
                  <InlineTooltip
                    maxWidth="20rem"
                    message={
                      <div>
                        To generate the revenue recognition ledger, please fix the following issues:
                        <UL>
                          {generateButtonStatus.errors.map((error, index) => (
                            <li key={index}>{error}</li>
                          ))}
                        </UL>
                      </div>
                    }
                  />
                )}
              </Button>
            </div>
          </Level.Item>

          <Level.Item right narrow>
            <DeleteButton disabled={!selected.some} onClick={handleDeleteMultiple}>
              Delete
            </DeleteButton>
          </Level.Item>

          <Level.Item right narrow>
            <Button data-testid="add-multiple-rev-rec-entries" isOutline onClick={handleAddMultiple}>
              Add Multiple
            </Button>
          </Level.Item>
        </Level>
      </Header>

      <Form.Control>
        <Table small>
          <Table.BoxHeader>
            <Table.Column width="3rem">
              <StyledCheckbox>
                <Checkbox
                  data-testid="revenue_recognition_entries_bulk_checkbox"
                  disabled={!selected.available.length}
                  checked={selected.some}
                  partial={!selected.all}
                  onChange={handleBulkSelectionChange}
                />
              </StyledCheckbox>
            </Table.Column>
            <Table.Column width="8rem">Date</Table.Column>
            <Table.Column>Notes</Table.Column>
            <Table.Column align="right" width="10rem">
              Amount
            </Table.Column>
            <Table.Column width="2rem" />
            <Table.BoxActionsColumn />
          </Table.BoxHeader>
          <Table.Body>
            {projectModel.revenueRecognitionEntries.map((item) => {
              const checked = selectedIds.some((id) => id === item.id);

              const handleSelectionChange = () => {
                setSelectedIds(checked ? selectedIds.filter((id) => id !== item.id) : [...selectedIds, item.id]);
              };

              return (
                <RevenueRecognitionEntryRow
                  key={item.id}
                  revenueRecognitionEntry={item}
                  currency={currency}
                  onEdit={() => handleEdit(item)}
                  onDelete={() => handleDelete(item)}
                  checked={checked}
                  onSelectionChange={() => handleSelectionChange(item)}
                />
              );
            })}

            <Table.Row>
              <Table.Cell>
                <Button isAnchor isStrong onClick={handleAdd}>
                  <Icon icon="plus" size="xs" spaceRight />
                  Quick Add
                </Button>
              </Table.Cell>
            </Table.Row>

            <Table.Row style={{ borderBottom: 'none', fontWeight: weights.bold, textTransform: 'uppercase' }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell>
                <Currency value={total.recognized} currency={currency} />
              </Table.Cell>
              <Table.Cell />
              <Table.Cell />
            </Table.Row>
          </Table.Body>
        </Table>
      </Form.Control>

      {formEntry && (
        <RevenueRecognitionEntryForm
          revenueRecognitionEntry={formEntry}
          currency={currency}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
}

function RevenueRecognitionEntryRow({
  currency,
  revenueRecognitionEntry: { date, notes, amount },
  onEdit,
  onDelete,
  onSelectionChange,
  checked,
}) {
  const confirmation = useConfirmation();

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>
        Are you sure you want to delete this revenue recognition entry?
      </DeleteConfirmation>
    ));
    if (!confirm) return;

    onDelete();
  };

  return (
    <Table.BoxRow data-testid="rev-rec-entry">
      <Table.Cell>
        <Checkbox data-testid={`checkbox_revenue_recognition_entry`} checked={checked} onChange={onSelectionChange} />
      </Table.Cell>
      <Table.Cell>
        <DateTime value={date} />
      </Table.Cell>
      <Table.Cell>{notes}</Table.Cell>
      <Table.Cell>
        <Currency value={amount} currency={currency} />
      </Table.Cell>
      <Table.Cell />
      <TableBoxRowActions>
        <TableBoxRowActions.Edit onClick={onEdit} />

        <hr />

        <TableBoxRowActions.Delete onClick={handleDelete} />
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function RevenueRecognitionEntryForm({ currency, revenueRecognitionEntry, onSubmit, onCancel }) {
  const initialValues = mergeValues(
    {
      id: null,
      date: moment().format(dateFormats.isoDate),
      notes: '',
      amount: '',
    },
    revenueRecognitionEntry,
  );

  return (
    <ModalCard
      title={initialValues.id ? 'Edit Revenue Recognition Entry' : 'Add Revenue Recognition Entry'}
      onClose={onCancel}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          date: Yup.date().label('Date').nullable().required(),
          notes: Yup.string().label('Notes').max(255),
          amount: Yup.number().label('Amount').min(-99999999999).max(99999999999).nullable().required(),
        })}>
        {() => {
          return (
            <Form>
              <ModalCard.Body>
                <Form.Control>
                  <Field.DayPicker name="date" placeholder="Date" clearable={false} />

                  <Field.Currency autoFocus name="amount" placeholder="Amount" currency={currency} />
                </Form.Control>

                <Form.Control>
                  <Field.Text name="notes" placeholder="Notes" maxLength={255} />
                </Form.Control>
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onCancel}>Close</CancelButton>

                  <Button type="submit">Save &amp; Close</Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

function MultipleRevenueRecognitionEntriesForm({ currency, project, onSubmit, onCancel }) {
  const { workspace } = useWorkspace();
  const api = useApi();

  const initialValues = {
    start: null,
    end: null,
    dayOfMonth: null,
    notes: '',
    amount: '',
  };

  const handleSubmit = async (values) => {
    const { start, end, dayOfMonth, notes, amount } = emptyStringToNull(values);

    const { data: dates } = await api.www
      .workspaces(workspace.id)
      .projects(project.id)
      .generateRecurringDates({ start, end, dayOfMonth });

    const instances = dates.map((date) => {
      return {
        date: moment(date).format(dateFormats.isoDate),
        notes,
        amount,
      };
    });

    onSubmit(instances);
  };

  return (
    <ModalCard title="Add Revenue Recognition Entries" onClose={onCancel}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          start: Yup.date().label('Starting Month').nullable().required(),
          end: Yup.date()
            .label('Ending Month')
            .nullable()
            .min(Yup.ref('start'), 'Ending Month must be after Starting Month')
            .required(),
          dayOfMonth: Yup.mixed()
            .label('Day of Month')
            .oneOf([...Array(28).keys()].map((i) => i + 1).concat('last'))
            .required(),
          notes: Yup.string().label('Notes').max(255),
          amount: Yup.number().label('Amount').min(-99999999999).max(99999999999).nullable().required(),
        })}>
        {({ submitForm }) => {
          return (
            <>
              <ModalCard.Body>
                <Form.Control>
                  <Field.DayPicker
                    name="start"
                    placeholder="Starting Month"
                    locale="en-US"
                    displayFormat={dateFormats.monthYear}
                    scope="month"
                  />
                  <Field.DayPicker
                    name="end"
                    placeholder="Ending Month"
                    locale="en-US"
                    displayFormat={dateFormats.monthYear}
                    scope="month"
                  />
                </Form.Control>
                <Form.Control>
                  <ContainerControl style={{ marginTop: '0' }}>
                    Scheduled on the
                    <div style={{ margin: '0 0.5rem' }}>
                      <Field.SingleSelect
                        name="dayOfMonth"
                        style={{ width: '7rem', margin: '0 0.5rem', textAlign: 'right' }}
                        materialPlaceholder={false}>
                        {[...Array(28).keys()].map((day) => (
                          <option key={day} value={day + 1}>
                            {day + 1}
                            {{
                              1: 'st',
                              2: 'nd',
                              3: 'rd',
                            }[day + 1] || 'th'}
                          </option>
                        ))}
                        <option value="last">Last</option>
                      </Field.SingleSelect>
                    </div>
                    <span>day of each month</span>
                  </ContainerControl>
                </Form.Control>

                <Form.Control>
                  <Field.Text name="notes" placeholder="Notes" maxLength={255} />

                  <Field.Currency autoFocus name="amount" placeholder="Amount" currency={currency} />
                </Form.Control>
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onCancel}>Close</CancelButton>

                  <Button onClick={submitForm}>Add</Button>
                </Buttons>
              </ModalCard.Footer>
            </>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default RevenueRecognitionLedger;
