import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Page, SearchInput } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import NavBox from './components/NavBox';
import NavSection from './components/NavSection';

const AccordionButton = styled(Button)`
  font-size: 0.75rem;
  color: ${colors.grey55};
  opacity: 0.5;

  &:hover {
    color: ${colors.grey55};
    opacity: 1;
  }
`;

const Search = styled.div`
  flex: 1;
  .icon {
    margin-right: 0.5rem;
  }
`;

const NoResults = styled.div`
  color: ${colors.grey40};
  font-size: 0.875rem;
  margin-top: 1.5rem;
`;

export default function SavedReportsPage() {
  useDocumentTitle('Saved Reports');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [savedReports, setSavedReports] = useState(null);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().saved.get();
    setSavedReports(data.map((r) => ({ ...r, path: `/saved/${r.id}` })));
  }, [api, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [params, setParams] = useState(() => ({ q: '' }));
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(() => ({ q: { default: '' } }), []),
    sessionKey: 'saved_reports',
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });
  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const config = useMemo(() => {
    if (!savedReports) return null;

    const filter = (reports) => {
      let results = reports;

      if (params.q) {
        const fuse = new Fuse(reports, {
          keys: ['name', 'description', 'section'],
          threshold: 0.1,
          ignoreLocation: true,
        });

        results = fuse.search(params.q).map(({ item }) => item);
      }

      return _.orderBy(results, ['title'], ['asc']);
    };

    const sections = [{ id: 'saved', title: 'Saved', reports: filter(savedReports ?? []) }];

    return sections.filter(({ reports }) => reports.length);
  }, [savedReports, params.q]);

  const handleFilter = ({ target: { name, value } }) => {
    setParams((params) => ({ ...params, [name]: value }));
    searchParams.set({ [name]: value });
  };

  if (!savedReports) return <PageLoader />;

  return (
    <Page>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Reports</Page.Eyebrow>
          <Page.Title>
            Saved <AccordionButton isAnchor />
          </Page.Title>
        </Page.Info>
      </Page.Header>

      <Page.Filters>
        <Search>
          <SearchInput name="q" value={params.q} onChange={handleFilter} placeholder="Search" autoFocus />
        </Search>
      </Page.Filters>

      {searchParamsStatus === 'pending' ? (
        <PageLoader />
      ) : (
        <>
          {config.map((section) => (
            <NavSection key={section.id} expanded>
              {_.map(section.reports, (report) => (
                <NavBox
                  key={report.path}
                  to={`/app/${workspace.key}/reports${report.path}`}
                  reportKey={report.key}
                  title={report.name}
                  description={report.description}
                />
              ))}
            </NavSection>
          ))}

          {!config.length && <NoResults>No results found.</NoResults>}
        </>
      )}
    </Page>
  );
}
