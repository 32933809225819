import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Page, SingleSelectFilter } from '~/components';
import { useLocalStorage } from '~/hooks';
import { intervalOptions } from '~/utils';
import Separator from './components/Separator';
import ProjectEffectiveRateWidget from './widgets/ProjectEffectiveRateWidget';
import ProjectExpensesWidget from './widgets/ProjectExpensesWidget';
import ProjectHoursWidget from './widgets/ProjectHoursWidget';
import ProjectMarginWidget from './widgets/ProjectMarginWidget';
import ProjectRealizationRateWidget from './widgets/ProjectRealizationRateWidget';
import ProjectRevenueWidget from './widgets/ProjectRevenueWidget';
import HoursWidget from './widgets/project-progress/hours/HoursWidget';
import MyHoursWidget from './widgets/project-progress/my-hours/MyHoursWidget';
import ServicesCostWidget from './widgets/project-progress/services-cost/ServicesCostWidget';
import ServicesMarginWidget from './widgets/project-progress/services-margin/ServicesMarginWidget';
import ServicesProfitWidget from './widgets/project-progress/services-profit/ServicesProfitWidget';
import ServicesRevenueProgress from './widgets/project-progress/services-revenue/ServicesRevenueWidget';
import TotalCostWidget from './widgets/project-progress/total-cost/TotalCostWidget';
import TotalMarginWidget from './widgets/project-progress/total-margin/TotalMarginWidget';
import TotalProfitWidget from './widgets/project-progress/total-profit/TotalProfitWidget';
import TotalRevenueWidget from './widgets/project-progress/total-revenue/TotalRevenueWidget';
import ExpensesRevenueProgress from './widgets/project-progress/expenses-revenue/ExpensesRevenueWIdget';
import ExpensesCostWidget from './widgets/project-progress/expenses-cost/ExpensesCostWidget';

export default function ProjectKPI({ project }) {
  const intervals = useMemo(
    () => [
      { ...intervalOptions.all_time, label: 'Performance to Date' },
      intervalOptions.this_month,
      intervalOptions.last_month,
    ],
    [],
  );

  const [period, setPeriod] = useState(
    project.useMonthlyBudget
      ? intervalOptions.this_month
      : { ...intervalOptions.all_time, label: 'Performance to Date' },
  );

  const kpi = {
    realizationRate: project.isBillable && project.permissions.viewTimeAndExpenses,
    effectiveRate: project.isBillable && project.permissions.viewRates,
    margin: project.isBillable && project.permissions.viewMargin,
    hours: project.permissions.viewTimeAndExpenses,
    expenses: project.permissions.viewTimeAndExpenses,
    revenue: project.isBillable && project.permissions.viewRevenue,
    cost: project.permissions.viewMargin,
    profit: project.isBillable && project.permissions.viewRevenue && project.permissions.viewMargin,
    get any() {
      return this.realizationRate || this.effectiveRate || this.margin || this.hours || this.expenses || this.revenue;
    },
    get progress() {
      const options = [{ value: 'myHours', label: 'My Hours' }];
      if (this.hours) options.push({ value: 'hours', label: 'Hours' });
      if (this.revenue) {
        options.push(
          { value: 'servicesRevenue', label: 'Services Revenue' },
          { value: 'expensesRevenue', label: 'Expenses Revenue' },
          { value: 'totalRevenue', label: 'Total Revenue' },
        );
      }
      if (this.cost) {
        options.push({ value: 'servicesCost', label: 'Services Cost' });
        options.push({ value: 'expensesCost', label: 'Expenses Cost' });
        options.push({ value: 'totalCost', label: 'Total Cost' });
      }
      if (this.profit) {
        options.push({ value: 'profit', label: 'Services Gross Profit' });
        options.push({ value: 'totalProfit', label: 'Total Gross Profit' });
        options.push({ value: 'margin', label: 'Services Gross Margin' });
        options.push({ value: 'totalMargin', label: 'Total Gross Margin' });
      }

      return options;
    },
  };

  const projectKey = _.snakeCase(project.key);

  const lastProgressMetric = useLocalStorage({ key: `project_dashboard_${projectKey}_progress_metric` });
  const [progressMetric, setProgressMetric] = useState(
    () =>
      kpi.progress.find(({ value }) => value === lastProgressMetric.get())?.value ??
      (project.permissions.viewTimeAndExpenses ? 'hours' : 'myHours'),
  );
  const handleProgressMetricChange = ({ target: { value } }) => {
    setProgressMetric(value);
    lastProgressMetric.set(value);
  };

  const lastProgressView = useLocalStorage({ key: `project_dashboard_${projectKey}_progress_view` });
  const [progressView, setProgressView] = useState(() => lastProgressView.get() ?? 'progress');
  const handleProgressViewChange = (value) => {
    setProgressView(value);
    lastProgressView.set(value);
  };

  return (
    <>
      <Page.TabHeader>
        <Page.Title>Project Progress</Page.Title>

        <Page.Actions>
          <div style={{ width: '12rem' }}>
            {kpi.progress && (
              <SingleSelectFilter
                clearable={false}
                value={progressMetric}
                options={kpi.progress.map(({ value, label }) => ({ id: value, name: label }))}
                onChange={handleProgressMetricChange}
              />
            )}
          </div>
        </Page.Actions>
      </Page.TabHeader>

      <Page.Section>
        {{
          myHours: () => (
            <MyHoursWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          hours: () => <HoursWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />,
          servicesRevenue: () => (
            <ServicesRevenueProgress project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          expensesRevenue: () => (
            <ExpensesRevenueProgress project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          totalRevenue: () => (
            <TotalRevenueWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          servicesCost: () => (
            <ServicesCostWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          expensesCost: () => (
            <ExpensesCostWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          totalCost: () => (
            <TotalCostWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          profit: () => (
            <ServicesProfitWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          totalProfit: () => (
            <TotalProfitWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          margin: () => (
            <ServicesMarginWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
          totalMargin: () => (
            <TotalMarginWidget project={project} view={progressView} onViewChange={handleProgressViewChange} />
          ),
        }[progressMetric]()}
      </Page.Section>

      <Separator />

      {kpi.any && (
        <>
          <Page.Header>
            <Page.Title>Key Performance Indicators</Page.Title>

            <Page.Actions>
              {/* TODO: use single select filter */}
              <div style={{ width: '12rem' }}>
                <SingleSelectFilter
                  clearable={false}
                  value={period}
                  renderValue={(value) => value.label}
                  options={_.map(intervals, ({ label, key }) => ({ id: key, name: label }))}
                  onChange={({ target: { value } }) => setPeriod(intervals.find(({ key }) => key === value))}
                />
              </div>
            </Page.Actions>
          </Page.Header>

          <Page.Section>
            <Page.Widgets>
              {kpi.realizationRate && <ProjectRealizationRateWidget project={project} period={period} />}

              {kpi.effectiveRate && <ProjectEffectiveRateWidget project={project} period={period} />}

              {kpi.margin && <ProjectMarginWidget project={project} period={period} />}

              {kpi.hours && <ProjectHoursWidget project={project} period={period} />}

              {kpi.expenses && <ProjectExpensesWidget project={project} period={period} />}

              {kpi.revenue && <ProjectRevenueWidget project={project} period={period} />}
            </Page.Widgets>
          </Page.Section>
        </>
      )}
    </>
  );
}
