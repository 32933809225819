import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Avatar,
  BackLink,
  ClientLink,
  Icon,
  InternalClientTooltip,
  MemberContactPopover,
  ProjectLink,
  RouteLink,
  Tag,
  Tooltip,
  ExportDropdown,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import MemberFiltersBar from '~/components/filters/MemberFiltersBar';
import MemberFiltersGroup from '~/components/filters/MemberFiltersGroup';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import ProjectTeamStatusFilter from '../components/ProjectTeamStatusFilter';
import Report from '../components/Report';
import Table from '../components/table';
import SavedReportActions from '../SavedReportActions';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const Member = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const MemberInfo = styled.span`
  margin-left: 0.5rem;
`;

const FirstRole = ({ member }) => {
  const role = member.roles[0];
  if (!role) return null;

  return role.name;
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Roles = ({ member }) => {
  let rolesCount = member.roles.length - 1;
  if (rolesCount <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Roles</Title>

          {member.roles.map((role) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={role.id}>
              <small>{role.name}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{rolesCount}</small>
      </Tag>
    </Tooltip>
  );
};

export default function ProjectMembers({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectRecordStatusId: { ...searchParamsConfig.recordStatusId, default: 'active' },
  }));
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    sort: new QuerySort('project.name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
    ...memberFilters.filters,
    projectTeamStatus: null,
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        sort: { default: new QuerySort('project.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
        projectTeamStatus: searchParamsConfig.projectTeamStatus,
      }),
      [searchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
      projectTeamStatusId: params.projectTeamStatus ?? undefined,
    }),
    [params, clientFilters, projectFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().projectMembers(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .projectMembers(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title>{report.name}</Report.Title>
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />

          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />

        <MemberFiltersBar filters={params} onChange={handleApplyFilters} />

        {!_.isEmpty(params.projectTeamStatus) && (
          <ProjectTeamStatusFilter
            value={params.projectTeamStatus}
            onChange={({ target: { value } }) => handleApplyFilters({ projectTeamStatus: value })}
          />
        )}
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const auth = useAuth();
  const { workspace } = useWorkspace();
  const features = useFeatures();

  const report = query.data;

  return (
    <Report.Table>
      <Table>
        <Table.Header>
          <Table.Column sticky minWidth="16rem" name="project.name" onSort={onSort} sort={params.sort}>
            Project/Client
          </Table.Column>
          <Table.Column width="6rem">Status</Table.Column>
          <Table.Column width="7rem" isVisible={auth.workspace.manage}>
            Type
          </Table.Column>
          <Table.Column minWidth="16rem">Project Role(s)</Table.Column>
          <Table.Column minWidth="16rem" isVisible={features.practices}>
            Practice
          </Table.Column>
          <Table.Column minWidth="16rem" isVisible={features.disciplines}>
            Discipline
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.status === 'sorting'}>
          {report.records.map((project) => {
            return (
              <React.Fragment key={project.id}>
                <Table.GroupRow top="3rem">
                  <Table.Cell>
                    <ProjectInfo>
                      <p>
                        <ProjectLink project={project} />
                      </p>
                      <small>
                        <ClientLink client={project.client} />
                        {project.client.isInternal && <InternalClientTooltip />}
                      </small>
                    </ProjectInfo>
                  </Table.Cell>
                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell />
                </Table.GroupRow>

                {project.members.map((pm) => {
                  return (
                    <Table.Row key={pm.id}>
                      <Table.Cell>
                        <Member style={{ marginLeft: '1rem' }}>
                          <MemberContactPopover member={pm.member} placement="right">
                            <div style={{ position: 'relative' }}>
                              <Avatar value={pm.member} isCircle hasBackground initialsFontSize=".9rem" />
                            </div>
                          </MemberContactPopover>
                          <MemberInfo>
                            {auth.workspace.manage ? (
                              <RouteLink to={`/app/${workspace.key}/settings/members/details/${pm.member.id}`}>
                                {pm.member.name}
                              </RouteLink>
                            ) : (
                              pm.member.name
                            )}
                          </MemberInfo>

                          {pm.typeId === 'administrator' && (
                            <Tooltip placement="right" message="Project Admin">
                              <Icon icon="user-gear" spaceLeft color={colors.primary} />
                            </Tooltip>
                          )}
                        </Member>
                      </Table.Cell>
                      <Table.Cell>{{ active: 'Active', inactive: 'Inactive' }[pm.projectTeamStatus]}</Table.Cell>
                      <Table.Cell>{pm.member.employmentType?.name}</Table.Cell>
                      <Table.Cell>
                        <FirstRole member={pm} />
                        <Roles member={pm} />
                      </Table.Cell>
                      <Table.Cell>{pm.member.practice?.name}</Table.Cell>
                      <Table.Cell>{pm.member.discipline?.name}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })}
        </Table.Body>

        <Table.Status
          total={report.records.length}
          label="Project"
          isLoading={query.status !== 'ready'}
          partial={report.partialResults}
        />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ProjectTeamStatusFilter
        value={filters.projectTeamStatus}
        onChange={({ target: { value } }) => handleFilter({ projectTeamStatus: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}
