import React, { useEffect } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import FiltersDrawer from '~/components/filters/FiltersDrawer';

const Article = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow-y: scroll;
  height: ${({ $height }) => $height};
  flex: unset;
`;

function Report(props) {
  useEffect(() => {
    document.body.style.overflowY = 'visible';

    return () => {
      document.body.style.overflowY = 'scroll';
    };
  }, []);

  // If the header isn't rendered yet, default to 60 px (the standard header height)
  const headerBottom = document.querySelector('#root > header')?.getBoundingClientRect().bottom ?? 60;
  return <Article $height={`calc(100vh - ${headerBottom}px)`} {...props} />;
}

const Summary = styled.div`
  margin-top: 1rem;
  transition: opacity 250ms;
  opacity: ${({ fade }) => (fade ? 0.1 : 1)};
  position: sticky;
  left: 0;
`;

const Status = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  position: sticky;
  left: 0;

  .icon {
    font-size: 0.875rem;
  }

  > div {
    display: flex;
    align-items: center;

    &:only-child {
      margin-left: auto;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  z-index: 6;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: ${weights.light};
  align-self: start;
  margin-top: 0.25rem;
`;

const Eyebrow = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-top: 0.1rem;
`;

const Actions = styled.div`
  display: flex;
  margin-left: auto;
  gap: 1rem;
`;

const FiltersBar = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  grid-column-gap: 0.375rem;
  grid-row-gap: 0.75rem;
  margin-top: 1rem;
  font-size: 0.875rem;
  position: sticky;
  left: 0;
  z-index: 5;

  &:empty {
    display: none;
  }

  &,
  & span > strong {
    font-size: 0.875rem;
  }

  & input {
    height: 2rem;
    font-size: 0.75rem;
  }
`;

Report.Table = styled.div`
  margin-top: 1.5rem;
  margin-left: -1rem;
  margin-right: -1rem;
`;

Report.Header = Header;
Report.Info = Info;
Report.Title = Title;
Report.Eyebrow = Eyebrow;
Report.Actions = Actions;
Report.FiltersBar = FiltersBar;
Report.Summary = Summary;
Report.FiltersDrawer = FiltersDrawer;
Report.Status = Status;

export default Report;
