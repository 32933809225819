import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Icon, Page, SearchInput } from '~/components';
import { useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams, useSessionStorage } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import NavBox from './components/NavBox';
import NavSection from './components/NavSection';
import useExpenseReports from './expenses/useExpenseReports';
import useAccountingReports from './financial/useAccountingReports';
import usePerformanceReports from './financial/usePerformanceReports';
import useForecastReports from './forecast/useForecastReports';
import usePlanReports from './forecast/usePlanReports';
import useVarianceReports from './forecast/useVarianceReports';
import usePipelineReports from './pipeline/usePipelineReports';
import useTimeReports from './time/useTimeReports';
import useFavoriteReports from './useFavoriteReports';
import useUtilizationReports from './utilization/useUtilizationReports';
import useWorkspaceReports from './workspace/useWorkspaceReports';

const AccordionButton = styled(Button)`
  font-size: 0.75rem;
  color: ${colors.grey55};
  opacity: 0.5;

  &:hover {
    color: ${colors.grey55};
    opacity: 1;
  }
`;

const Search = styled.div`
  flex: 1;
  .icon {
    margin-right: 0.5rem;
  }
`;

const NoResults = styled.div`
  color: ${colors.grey40};
  font-size: 0.875rem;
  margin-top: 1.5rem;
`;

export default function ReportsPage() {
  useDocumentTitle('Reports Library');

  const { workspace } = useWorkspace();

  const favoriteReports = useFavoriteReports();
  const timeReports = useTimeReports();
  const expenseReports = useExpenseReports();
  const utilizationReports = useUtilizationReports();
  const planReports = usePlanReports();
  const forecastReports = useForecastReports();
  const varianceReports = useVarianceReports();
  const accountingReports = useAccountingReports();
  const performanceReports = usePerformanceReports();
  const pipelineReports = usePipelineReports();
  const workspaceReports = useWorkspaceReports();

  const sessionStorage = useSessionStorage({ key: 'reports__params' });

  const [sections, setSections] = useState(() => {
    const sections = {
      favorites: true,
      time: true,
      expenses: true,
      utilization: true,
      forecast: true,
      plan: true,
      variance: true,
      accounting: true,
      performance: true,
      pipeline: true,
      workspace: true,
    };
    try {
      if (sessionStorage.hasValue()) {
        const value = JSON.parse(sessionStorage.get());
        if (value) {
          sections.favorites = !!value.favorites;
          sections.time = !!value.time;
          sections.expenses = !!value.expenses;
          sections.utilization = !!value.utilization;
          sections.forecast = !!value.forecast;
          sections.plan = !!value.plan;
          sections.variance = !!value.variance;
          sections.accounting = !!value.accounting;
          sections.performance = !!value.performance;
          sections.pipeline = !!value.pipeline;
          sections.workspace = !!value.workspace;
        }
      }
    } finally {
      sessionStorage.set(JSON.stringify(sections));
    }
    return sections;
  });

  const all = Object.keys(sections).every((section) => sections[section]);

  const toggleSection = (section) => {
    const value = { ...sections, [section]: !sections[section] };
    setSections(value);
    sessionStorage.set(JSON.stringify(value));
  };

  const toggleAllSections = () => {
    const value = {
      favorites: !all,
      time: !all,
      expenses: !all,
      utilization: !all,
      forecast: !all,
      plan: !all,
      variance: !all,
      accounting: !all,
      performance: !all,
      pipeline: !all,
      workspace: !all,
    };

    setSections(value);
    sessionStorage.set(JSON.stringify(value));
  };

  const [params, setParams] = useState(() => ({ q: '' }));
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(() => ({ q: { default: '' } }), []),
    sessionKey: 'reports__sections',
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });
  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const config = useMemo(() => {
    const filter = (reports) => {
      let results = reports;

      if (params.q) {
        const fuse = new Fuse(reports, {
          keys: ['name', 'description', 'section'],
          threshold: 0.1,
          ignoreLocation: true,
        });

        results = fuse.search(params.q).map(({ item }) => item);
      }

      return _.orderBy(results, ['title'], ['asc']);
    };

    const sections = [
      { id: 'favorites', title: 'Favorites', reports: filter(favoriteReports) },
      { id: 'time', title: 'Time', reports: filter(timeReports) },
      { id: 'expenses', title: 'Expenses', reports: filter(expenseReports) },
      { id: 'utilization', title: 'Utilization', reports: filter(utilizationReports) },
      { id: 'forecast', title: 'Forecast', reports: filter(forecastReports) },
      { id: 'plan', title: 'Plan', reports: filter(planReports) },
      { id: 'variance', title: 'Variance', reports: filter(varianceReports) },
      { id: 'accounting', title: 'Accounting', reports: filter(accountingReports) },
      { id: 'performance', title: 'Performance', reports: filter(performanceReports) },
      { id: 'pipeline', title: 'Pipeline', reports: filter(pipelineReports) },
      { id: 'workspace', title: 'Workspace', reports: filter(workspaceReports) },
    ];

    return sections.filter(({ reports }) => reports.length);
  }, [
    favoriteReports,
    timeReports,
    expenseReports,
    utilizationReports,
    planReports,
    forecastReports,
    varianceReports,
    accountingReports,
    performanceReports,
    pipelineReports,
    workspaceReports,
    params.q,
  ]);

  const handleFilter = ({ target: { name, value } }) => {
    setParams((params) => ({ ...params, [name]: value }));
    searchParams.set({ [name]: value });
  };

  return (
    <Page>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Reports</Page.Eyebrow>
          <Page.Title>
            Library{' '}
            <AccordionButton isAnchor onClick={() => toggleAllSections()}>
              <Icon icon={all ? 'angle-double-down' : 'angle-double-up'} />
            </AccordionButton>
          </Page.Title>
        </Page.Info>
      </Page.Header>

      <Page.Filters>
        <Search>
          <SearchInput name="q" value={params.q} onChange={handleFilter} placeholder="Search" autoFocus />
        </Search>
      </Page.Filters>

      {searchParamsStatus === 'pending' ? (
        <PageLoader />
      ) : (
        <>
          {config.map((section) => (
            <NavSection
              key={section.id}
              title={section.title}
              expanded={sections[section.id]}
              onToggle={() => toggleSection(section.id)}>
              {_.map(section.reports, (report) => (
                <NavBox
                  key={report.path}
                  to={`/app/${workspace.key}/reports${report.path}`}
                  reportKey={report.key}
                  title={report.name}
                  description={report.description}
                />
              ))}
            </NavSection>
          ))}

          {!config.length && <NoResults>No results found.</NoResults>}
        </>
      )}
    </Page>
  );
}
