import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { PageLoader } from '~/routes/public/pages';
import useExpenseReports from './expenses/useExpenseReports';
import useAccountingReports from './financial/useAccountingReports';
import usePerformanceReports from './financial/usePerformanceReports';
import useForecastReports from './forecast/useForecastReports';
import usePlanReports from './forecast/usePlanReports';
import useVarianceReports from './forecast/useVarianceReports';
import usePipelineReports from './pipeline/usePipelineReports';
import useTimeReports from './time/useTimeReports';
import useUtilizationReports from './utilization/useUtilizationReports';
import useWorkspaceReports from './workspace/useWorkspaceReports';

export const SavedReportContext = React.createContext();

export default function SavedReportPage() {
  const { workspace } = useWorkspace();
  const api = useApi();
  const { savedReportId } = useParams();
  const history = useHistory();
  const toast = useToast();
  const [savedReport, setSavedReport] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).reports().saved.get(savedReportId);
      try {
        const params = new URLSearchParams(data.params);
        history.replace(`/app/${workspace.key}/reports/saved/${data.id}?${params.toString()}`);
      } catch (error) {
        toast.error('Failed to parse saved report parameters.');
      }
      setSavedReport(data);
    } catch (error) {
      toast.error('Failed to load saved report.');
      history.replace(`/app/${workspace.key}/reports`);
    }
  }, [api, workspace.id, workspace.key, savedReportId, toast, history]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const timeReports = useTimeReports();
  const expenseReports = useExpenseReports();
  const utilizationReports = useUtilizationReports();
  const planReports = usePlanReports();
  const forecastReports = useForecastReports();
  const varianceReports = useVarianceReports();
  const accountingReports = useAccountingReports();
  const performanceReports = usePerformanceReports();
  const pipelineReports = usePipelineReports();
  const workspaceReports = useWorkspaceReports();

  const reports = [
    ...timeReports,
    ...expenseReports,
    ...utilizationReports,
    ...planReports,
    ...forecastReports,
    ...varianceReports,
    ...accountingReports,
    ...performanceReports,
    ...pipelineReports,
    ...workspaceReports,
  ];

  const report = reports.find((report) => report.key === savedReport?.reportKey);

  if (!report) return <PageLoader />;

  const Component = report.component;

  return (
    <SavedReportContext.Provider value={{ savedReport, onRefetch: fetchData }}>
      <Component report={savedReport} backLinkPath={`/app/${workspace.key}/reports/saved`} />
    </SavedReportContext.Provider>
  );
}
