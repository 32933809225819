import { Hours, RouteLink, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { QueryString, dateFormats } from '~/utils';
import {
  Box,
  Boxes,
  ChartContainer,
  Label,
  LowerBoxes,
  Metric,
  SmallBox,
  UpperBox,
  Value,
} from '../components/StyledComponents';
import ViewNav from '../components/ViewNav';
import MyHoursByMonthChart from './MyHoursByMonthChart';
import MyHoursProgressChart from './MyHoursProgressChart';

export default function MyHoursWidget({ project, view, onViewChange }) {
  const { workspace } = useWorkspace();

  const api = useApi();
  const [{ data, isReady }, setQuery] = useState({ data: null, isReady: false });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).projects(project.id).dashboard().progress.myHours();
      setQuery({ data, isReady: true });
    } catch (error) {
      setQuery({ data: null, isReady: true });
    }
  }, [workspace.id, project.id, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData, project]);

  const timeDetail = (query = {}) =>
    `/app/${workspace.key}/reports/time-entries${new QueryString({
      start: 'not_set',
      end: moment().format(dateFormats.isoDate),
      project: project.id,
      ...query,
    }).toString(true)}`;

  const url = {
    total: timeDetail(),
    billable: timeDetail({ billableType: 'billable' }),
    non_billable: timeDetail({ billableType: project.client.isInternal ? 'internal' : 'non_billable' }),
  };

  const boxes = {
    forecast: data?.forecast?.dates.length > 0,
    get any() {
      return this.forecast;
    },
  };

  return (
    <Widget loading={!isReady}>
      <Widget.Preview style={{ minHeight: '32.4rem' }} />
      <Widget.Content>
        <ViewNav view={view} onViewChange={onViewChange} />

        {data && (
          <>
            <ChartContainer>
              {data &&
                {
                  progress: () => <MyHoursProgressChart project={project} data={data} />,
                  month: () => <MyHoursByMonthChart project={project} data={data} />,
                }[view]()}
            </ChartContainer>
            <Boxes>
              {project.isBillable && (
                <Box>
                  <UpperBox>
                    <RouteLink to={url.billable}>
                      <Value>
                        <Hours value={data.actual.billable} minimumFractionDigits={0} />
                      </Value>
                      <Label>Billable Hours</Label>
                    </RouteLink>
                  </UpperBox>

                  {boxes.any && (
                    <LowerBoxes>
                      {boxes.forecast && (
                        <SmallBox>
                          <Metric>
                            <Value>
                              <Hours value={data.forecast.billable.forecasted} minimumFractionDigits={0} />
                            </Value>
                            <Label>Forecast</Label>
                          </Metric>
                          <Metric>
                            <Value>
                              <Hours value={data.forecast.billable.left} minimumFractionDigits={0} />
                            </Value>
                            <Label>Forecast Left</Label>
                          </Metric>
                        </SmallBox>
                      )}
                    </LowerBoxes>
                  )}
                </Box>
              )}

              <Box>
                <UpperBox>
                  <RouteLink to={url.non_billable}>
                    <Value>
                      <Hours value={data.actual.nonBillable} minimumFractionDigits={0} />
                    </Value>
                    <Label>Non-billable Hours</Label>
                  </RouteLink>
                </UpperBox>

                {boxes.any && (
                  <LowerBoxes>
                    {boxes.forecast && (
                      <SmallBox>
                        <Metric>
                          <Value>
                            <Hours value={data.forecast.nonBillable.forecasted} minimumFractionDigits={0} />
                          </Value>
                          <Label>Forecast</Label>
                        </Metric>
                        <Metric>
                          <Value>
                            <Hours value={data.forecast.nonBillable.left} minimumFractionDigits={0} />
                          </Value>
                          <Label>Forecast Left</Label>
                        </Metric>
                      </SmallBox>
                    )}
                  </LowerBoxes>
                )}
              </Box>

              <Box>
                <UpperBox>
                  <RouteLink to={url.total}>
                    <Value>
                      <Hours value={data.actual.total} minimumFractionDigits={0} />
                    </Value>
                    <Label>Total Hours</Label>
                  </RouteLink>
                </UpperBox>

                {boxes.any && (
                  <LowerBoxes>
                    {boxes.forecast && (
                      <SmallBox>
                        <Metric>
                          <Value>
                            <Hours value={data.forecast.total.forecasted} minimumFractionDigits={0} />
                          </Value>
                          <Label>Forecast</Label>
                        </Metric>
                        <Metric>
                          <Value>
                            <Hours value={data.forecast.total.left} minimumFractionDigits={0} />
                          </Value>
                          <Label>Forecast Left</Label>
                        </Metric>
                      </SmallBox>
                    )}
                  </LowerBoxes>
                )}
              </Box>
            </Boxes>
          </>
        )}
      </Widget.Content>
    </Widget>
  );
}
