import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BackLink, ClientLink, Currency, ExportDropdown, RouteLink } from '~/components';
import PurchaseOrderStatusFilter from '~/components/filters/PurchaseOrderStatusFilter';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import { PageLoader } from '~/routes/public/pages';
import { weights } from '~/styles';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import CurrencyFilter from '~/components/filters/CurrencyFilter';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import FiltersGroup from '~/components/filters/FiltersGroup';
import Report from '../components/Report';
import Table from '../components/table';
import SavedReportActions from '../SavedReportActions';

const Byline = styled.small`
  display: block;
`;

export default function ClientPurchaseOrders({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const clientFilters = useClientFilters();

  const [params, setParams] = useState({
    purchaseOrderStatus: 'active',
    currency: workspace.currency,
    sort: new QuerySort('client.name', 'asc'),
    ...clientFilters.filters,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        purchaseOrderStatus: {
          default: 'active',
          valid: ['all', 'active', 'inactive'],
          serialize: function (value) {
            return this.default && !value ? 'all' : value;
          },
          deserialize: (value) => (value === 'all' ? null : value),
        },
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('client.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      purchaseOrderStatus: params.purchaseOrderStatus ?? undefined,
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().clientPurchaseOrders(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .clientPurchaseOrders(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  if (!query.data) return null;
  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title>{report.name}</Report.Title>
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
          <FilterButton isOutline onClick={showFilters} data-testid="filters_button" />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}
        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />
        {!_.isEmpty(params.purchaseOrderStatus) && (
          <PurchaseOrderStatusFilter
            value={params.purchaseOrderStatus}
            onChange={({ target: { value } }) => handleApplyFilters({ purchaseOrderStatus: value })}
          />
        )}
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;
  const { workspace } = useWorkspace();

  return (
    <Report.Table>
      <Table>
        <Table.Header>
          <Table.Column width="16rem" name="purchaseOrder.number" onSort={onSort} sort={params.sort}>
            #
          </Table.Column>
          <Table.Column width="16rem" name="client.name" onSort={onSort} sort={params.sort}>
            Client
          </Table.Column>
          <Table.Column align="right" name="purchaseOrder.amount" onSort={onSort} sort={params.sort}>
            PO Amount
          </Table.Column>
          <Table.Column align="right">Amount Invoiced</Table.Column>
          <Table.Column align="right">Amount Available</Table.Column>
        </Table.Header>

        <Table.Body fade={query.loading.table}>
          {report.records.map((purchaseOrder) => {
            return (
              <Table.Row key={purchaseOrder.id}>
                <Table.Cell>
                  <RouteLink
                    to={`/app/${workspace.key}/clients/${purchaseOrder.client.key}/purchase-orders/${purchaseOrder.id}/edit`}>
                    {purchaseOrder.number}
                  </RouteLink>
                </Table.Cell>
                <Table.Cell>
                  <ClientLink client={purchaseOrder.client} />
                </Table.Cell>
                <Table.Cell>
                  <p>
                    <Currency value={purchaseOrder.convertedAmount} currency={report.currency} />
                    {purchaseOrder.currency !== report.currency && (
                      <Byline>
                        <Currency value={purchaseOrder.amount} currency={purchaseOrder.currency} />
                      </Byline>
                    )}
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    <Currency value={purchaseOrder.convertedInvoicedAmount} currency={report.currency} />
                    {purchaseOrder.currency !== report.currency && (
                      <Byline>
                        <Currency value={purchaseOrder.invoicedAmount} currency={purchaseOrder.currency} />
                      </Byline>
                    )}
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    <Currency value={purchaseOrder.convertedRemainingAmount} currency={report.currency} />
                    {purchaseOrder.currency !== report.currency && (
                      <Byline>
                        <Currency value={purchaseOrder.remainingAmount} currency={purchaseOrder.currency} />
                      </Byline>
                    )}
                  </p>
                </Table.Cell>
              </Table.Row>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.totalAmount} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.totalInvoicedAmount} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.totalRemainingAmount} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>

        <Table.Status
          total={report.records.length}
          label="Purchase Order"
          isLoading={query.status !== 'ready'}
          partial={report.partialResults}
        />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup label="Purchase Order Filters" filters={[filters.purchaseOrderStatus]}>
        <PurchaseOrderStatusFilter
          value={filters.purchaseOrderStatus}
          onChange={({ target: { value } }) => handleFilter({ purchaseOrderStatus: value })}
        />
      </FiltersGroup>
    </Report.FiltersDrawer>
  );
}
