import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Avatar, BackLink, DateTime, MemberContactPopover, RouteLink, ExportDropdown } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useMemberFilters from '~/hooks/useMemberFilters';
import { PageLoader } from '~/routes/public/pages';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import MemberFiltersBar from '~/components/filters/MemberFiltersBar';
import MemberFiltersGroup from '~/components/filters/MemberFiltersGroup';
import Report from '../components/Report';
import Table from '../components/table';
import SavedReportActions from '../SavedReportActions';

const Member = styled.div`
  display: flex;
  align-items: center;
`;

const MemberInfo = styled.span`
  margin-left: 0.5rem;
`;

function MembersList({ report, backLinkPath }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const searchParamsConfig = useSearchParamsConfig();

  const memberFilters = useMemberFilters(() => ({
    memberStatus: { ...searchParamsConfig.memberStatus, default: 'active' },
  }));

  const [params, setParams] = useState({
    securityRoles: [],
    sort: new QuerySort('member.name', 'asc'),
    ...memberFilters.filters,
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        securityRoles: searchParamsConfig.securityRoles,
        sort: { default: new QuerySort('member.name', 'asc'), ...searchParamsConfig.sort },
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, memberFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      securityRoleId: params.securityRoles?.map((v) => v.id),
      sort: params.sort,
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().membersList(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .membersList(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={backLinkPath} ignoreHistory />

        <Report.Info>
          <Report.Eyebrow>Reports</Report.Eyebrow>
          <Report.Title>{report.name}</Report.Title>
        </Report.Info>

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />

          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <MemberFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const auth = useAuth();
  const { workspace } = useWorkspace();

  const report = query.data;

  return (
    <Report.Table>
      <Table>
        <Table.Header>
          <Table.Column sticky minWidth="16rem" name="member.name" onSort={onSort} sort={params.sort}>
            Name
          </Table.Column>
          <Table.Column name="employmentTypeId" width="7.5rem" onSort={onSort} sort={params.sort}>
            Type
          </Table.Column>
          <Table.Column name="isBillable" width="7.5rem" onSort={onSort} sort={params.sort}>
            Billability
          </Table.Column>
          <Table.Column name="securityRole.name" width="13rem" onSort={onSort} sort={params.sort}>
            Security Role
          </Table.Column>
          <Table.Column name="isActive" width="6rem" onSort={onSort} sort={params.sort}>
            Status
          </Table.Column>
          <Table.Column name="manager.name" minWidth="16rem" onSort={onSort} sort={params.sort}>
            Manager
          </Table.Column>
          <Table.Column name="accessedAt" width="9rem" align="right" onSort={onSort} sort={params.sort}>
            Last Active
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.status === 'sorting'}>
          {report.records.map((member) => {
            return (
              <Table.Row key={member.id}>
                <Table.Cell>
                  <Member>
                    <MemberContactPopover member={member} placement="right">
                      <Avatar value={member} isCircle hasBackground initialsFontSize=".9rem" />
                    </MemberContactPopover>
                    <MemberInfo>
                      {auth.workspace.manage ? (
                        <RouteLink to={`/app/${workspace.key}/settings/members/details/${member.id}`}>
                          {member.name}
                        </RouteLink>
                      ) : (
                        member.name
                      )}
                    </MemberInfo>
                  </Member>
                </Table.Cell>
                <Table.Cell>{member.employmentType?.name}</Table.Cell>
                <Table.Cell>{member.isBillable ? 'Billable' : 'Non-Billable'}</Table.Cell>
                <Table.Cell>{member.securityRole.name}</Table.Cell>
                <Table.Cell>{member.isActive ? 'Active' : 'Inactive'}</Table.Cell>
                <Table.Cell>
                  {member.manager && (
                    <Member>
                      <MemberContactPopover member={member.manager} placement="left">
                        <Avatar value={member.manager} isCircle hasBackground initialsFontSize=".9rem" />
                      </MemberContactPopover>
                      <MemberInfo>
                        {auth.workspace.manage ? (
                          <RouteLink to={`/app/${workspace.key}/settings/members/details/${member.manager.id}`}>
                            {member.manager.name}
                          </RouteLink>
                        ) : (
                          member.manager.name
                        )}
                      </MemberInfo>
                    </Member>
                  )}
                </Table.Cell>
                <Table.Cell>{member.accessedAt ? <DateTime value={member.accessedAt} /> : 'Never'}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>

        <Table.Status
          total={report.records.length}
          label="Member"
          isLoading={query.status !== 'ready'}
          partial={report.partialResults}
        />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <MemberFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

export default MembersList;
