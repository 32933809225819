import { useFeatures } from '~/hooks';
import TimeApprovalSummaryByMember from './TimeApprovalSummaryByMember';
import TimeAttachments from './TimeAttachments';
import TimeDetail from './TimeDetail';
import TimeEntriesPendingApproval from './TimeEntriesPendingApproval';
import TimeOffByMember from './TimeOffByMember';
import TimeSummaryByClient from './TimeSummaryByClient';
import TimeSummaryByClientAndMember from './TimeSummaryByClientAndMember';
import TimeSummaryByClientAndProject from './TimeSummaryByClientAndProject';
import TimeSummaryByClientByTimeUnit from './TimeSummaryByClientByTimeUnit';
import TimeSummaryByMember from './TimeSummaryByMember';
import TimeSummaryByMemberAndClient from './TimeSummaryByMemberAndClient';
import TimeSummaryByMemberAndProject from './TimeSummaryByMemberAndProject';
import TimeSummaryByMemberByTimeUnit from './TimeSummaryByMemberByTimeUnit';
import TimeSummaryByMemberPractice from './TimeSummaryByMemberPractice';
import TimeSummaryByProject from './TimeSummaryByProject';
import TimeSummaryByProjectAndMember from './TimeSummaryByProjectAndMember';
import TimeSummaryByProjectAndRole from './TimeSummaryByProjectAndRole';
import TimeSummaryByProjectAndTask from './TimeSummaryByProjectAndTask';
import TimeSummaryByProjectByTimeUnit from './TimeSummaryByProjectByTimeUnit';
import TimeSummaryByProjectType from './TimeSummaryByProjectType';
import UnsubmittedTimesheets from './UnsubmittedTimesheets';

export default function useTimeReports() {
  const features = useFeatures();

  return [
    {
      key: 'time_approval_summary_by_member',
      name: 'Time Approval Summary by Member',
      description:
        'A summary of hours by workspace member including not submitted, pending approval, approved, and rejected hours.',
      path: '/time-approval-summary-by-member',
      component: TimeApprovalSummaryByMember,
    },
    features.timeAttachments && {
      key: 'time_attachments',
      name: 'Time Attachments',
      description: 'Files that have been uploaded to the week view of the time screen.',
      path: '/time-attachments',
      component: TimeAttachments,
    },
    {
      key: 'time_entries',
      name: 'Time Entries',
      description:
        'Individual time entries including the date, member, project, client, role, task, and approval status.',
      path: '/time-entries',
      component: TimeDetail,
    },
    {
      key: 'time_entries_pending_approval',
      name: 'Time Entries Pending Approval',
      description:
        'Individual time entries in the Pending Approval status including the names of the pending approvers.',
      path: '/time-entries-pending-approval',
      component: TimeEntriesPendingApproval,
    },
    {
      key: 'time_off_by_member',
      name: 'Time Off by Member',
      description: 'A summary of hours grouped by member and time off type.',
      path: '/time-off-by-member',
      component: TimeOffByMember,
    },
    {
      key: 'time_summary_by_client',
      name: 'Time Summary by Client',
      description:
        'A summary of hours by client including client billable, client non-billable, internal, and time off hours.',
      path: '/time-summary-by-client',
      component: TimeSummaryByClient,
    },
    {
      key: 'time_summary_by_client_and_member',
      name: 'Time Summary by Client and Member',
      description:
        'A summary of hours grouped by client and member including client billable, client non-billable and internal hours.',
      path: '/time-summary-by-client-and-member',
      component: TimeSummaryByClientAndMember,
    },
    {
      key: 'time_summary_by_client_and_project',
      name: 'Time Summary by Client and Project',
      description:
        'A summary of hours grouped by client and project including client billable, client non-billable and internal hours.',
      path: '/time-summary-by-client-and-project',
      component: TimeSummaryByClientAndProject,
    },
    {
      key: 'time_summary_by_client_by_time_unit',
      name: 'Time Summary by Client by Time Unit',
      description: 'A summary of hours by client by time unit.',
      path: '/time-summary-by-client-by-time-unit',
      component: TimeSummaryByClientByTimeUnit,
    },
    {
      key: 'time_summary_by_member',
      name: 'Time Summary by Member',
      description:
        'A summary of hours by workspace member including client billable, client non-billable, internal, and time off hours.',
      path: '/time-summary-by-member',
      component: TimeSummaryByMember,
    },
    {
      key: 'time_summary_by_member_and_client',
      name: 'Time Summary by Member and Client',
      description:
        'A summary of hours grouped by member and client including client billable, client non-billable and internal hours.',
      path: '/time-summary-by-member-and-client',
      component: TimeSummaryByMemberAndClient,
    },
    {
      key: 'time_summary_by_member_and_project',
      name: 'Time Summary by Member and Project',
      description:
        'A summary of hours grouped by member and project including client billable, client non-billable and internal hours.',
      path: '/time-summary-by-member-and-project',
      component: TimeSummaryByMemberAndProject,
    },
    {
      key: 'time_summary_by_member_by_time_unit',
      name: 'Time Summary by Member by Time Unit',
      description: 'A summary of hours by member by time unit.',
      path: '/time-summary-by-member-by-time-unit',
      component: TimeSummaryByMemberByTimeUnit,
    },
    features.practices && {
      key: 'time_summary_by_member_practice',
      name: 'Time Summary by Member Practice',
      description:
        'A summary of hours by member practice including client billable, client non-billable, internal, and time off hours.',
      path: '/time-summary-by-member-practice',
      component: TimeSummaryByMemberPractice,
    },
    {
      key: 'time_summary_by_project',
      name: 'Time Summary by Project',
      description: 'A summary of hours by project including client billable, client non-billable, and internal hours.',
      path: '/time-summary-by-project',
      component: TimeSummaryByProject,
    },
    {
      key: 'time_summary_by_project_and_member',
      name: 'Time Summary by Project and Member',
      description:
        'A summary of hours grouped by project and member including client billable, client non-billable and internal hours.',
      path: '/time-summary-by-project-and-member',
      component: TimeSummaryByProjectAndMember,
    },
    {
      key: 'time_summary_by_project_and_role',
      name: 'Time Summary by Project and Role',
      description:
        'A summary of hours grouped by project and role including client billable, client non-billable and internal hours.',
      path: '/time-summary-by-project-and-role',
      component: TimeSummaryByProjectAndRole,
    },
    {
      key: 'time_summary_by_project_and_task',
      name: 'Time Summary by Project and Task',
      description:
        'A summary of hours grouped by project and task including client billable, client non-billable and internal hours.',
      path: '/time-summary-by-project-and-task',
      component: TimeSummaryByProjectAndTask,
    },
    {
      key: 'time_summary_by_project_by_time_unit',
      name: 'Time Summary by Project by Time Unit',
      description: 'A summary of hours by project by time unit.',
      path: '/time-summary-by-project-by-time-unit',
      component: TimeSummaryByProjectByTimeUnit,
    },
    {
      key: 'time_summary_by_project_type',
      name: 'Time Summary by Project Type',
      description:
        'A summary of hours by project type including client billable, client non-billable, and internal hours.',
      path: '/time-summary-by-project-type',
      component: TimeSummaryByProjectType,
    },
    features.timesheets && {
      key: 'unsubmitted_timesheets',
      name: 'Unsubmitted Timesheets',
      description: 'Unsubmitted timesheets including the timesheet period, member, tracked hours and capacity.',
      path: '/unsubmitted-timesheets',
      component: UnsubmittedTimesheets,
    },
  ].filter(Boolean);
}
